import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { login, setCurrentUserGroup } from "../store/interactions";
import { Auth, API } from "aws-amplify";
import Form from "react-bootstrap/Form";
import { useHistory } from "react-router-dom";
import LoaderButton from "../components/LoaderButton";
import { useFormFields } from "../libs/hooksLib";
import { onError } from "../libs/errorLib";
import config from "../config";
import "./Signup.css";

export default function Signup() {
  const dispatch = useDispatch();
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: "",
    confirmPassword: "",
    confirmationCode: "",
  });
  const history = useHistory();
  const [newUser, setNewUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const initialUserProfile = {
    userFirstName: "",
    userLastName: "",
    userEmail: fields.email,
    sponsorEmail: "user#" + fields.email, // need this to be unique as we are using a global index where sponsorEmail is the partition key
    inviteCode: "",
    inviteCodeIsActive: false,
    authorizeCode: "",
    authorizeCodeIsValid: false,
    paidToSponsorAmount: "",
    paidUserCount: 0,
    invitedUserCount: 0,
    totalPaymentsCount: 0,
    totalPayoutsCount: 0,
    totalDisbursements: 0,
    totalRemainingBalance: 0,
    invitedUsers: [],
    stripeLoginLink: "",
    stripePaymentsData: [],
    stripePayoutsData: [],
    accumulatedTokens: 0,
    disbursedTokens: 0,
    walletAddress: "",
    nonUSResidentsToSAccepted: false,
    stripeInitialDataSeeded: false,
    fullyOnboarded: false,
    connectOnboarded: false,
    connectOnboardedDate: 0,
    stripeAccountId: "",
    stripeCustomerd: "",
    subscriptionId: "",
    rewardsEnrolledDate: 0,
    rewardsRenewalDate: 0,
    subscriptionTier: "free",
    subscriptionCount: "",
  };

  function validateForm() {
    return (
      fields.email.length > 0 &&
      fields.password.length > 0 &&
      fields.password === fields.confirmPassword
    );
  }

  function validateConfirmationForm() {
    return fields.confirmationCode.length > 0;
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      const newUser = await Auth.signUp({
        username: fields.email,
        password: fields.password,
      });
      setIsLoading(false);
      setNewUser(newUser);
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }

  async function handleConfirmationSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      const confirmSignup = await Auth.confirmSignUp(
        fields.email,
        fields.confirmationCode
      );
      console.log("confirmSignup", confirmSignup);
      if (confirmSignup === "SUCCESS") {
        await login(fields.email, fields.password, dispatch);
        await setCurrentUserGroup(dispatch);
        const userProfile = await createUserProfile(initialUserProfile);
        if (userProfile) {
          config.isDemo && console.log(initialUserProfile);
          history.push("/");
        }
      } else {
        try {
          const deleteUser = await Auth.deleteUser();
          console.log("deleteUser", deleteUser);
        } catch (error) {
          onError(e);
          console.log("Error deleting user", error);
        }
      }
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }

  function createUserProfile(userprofile) {
    return API.post("userprofiles", "/userprofiles", {
      body: userprofile,
    });
  }

  function renderConfirmationForm() {
    return (
      <Form onSubmit={handleConfirmationSubmit}>
        <Form.Group controlId="confirmationCode" size="lg">
          <Form.Label>Confirmation Code</Form.Label>
          <Form.Control
            autoFocus
            type="tel"
            onChange={handleFieldChange}
            value={fields.confirmationCode}
          />
          <Form.Text muted>Please check your email for the code.</Form.Text>
        </Form.Group>
        <LoaderButton
          block
          size="lg"
          type="submit"
          variant="success"
          isLoading={isLoading}
          disabled={!validateConfirmationForm()}
        >
          Verify
        </LoaderButton>
      </Form>
    );
  }

  function renderForm() {
    return (
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="email" size="lg">
          <Form.Label>Email</Form.Label>
          <Form.Control
            autoFocus
            type="email"
            value={fields.email}
            onChange={handleFieldChange}
          />
        </Form.Group>
        <Form.Group controlId="password" size="lg">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            value={fields.password}
            onChange={handleFieldChange}
          />
          <span>
            At least 8 characters long containing a mix of letters (uppercase,
            lowercase), numbers, and special characters
          </span>
        </Form.Group>
        <Form.Group controlId="confirmPassword" size="lg">
          <Form.Label>Confirm Password</Form.Label>
          <Form.Control
            type="password"
            onChange={handleFieldChange}
            value={fields.confirmPassword}
          />
        </Form.Group>
        <LoaderButton
          block
          size="lg"
          type="submit"
          variant="msteams"
          isLoading={isLoading}
          disabled={!validateForm()}
        >
          Signup
        </LoaderButton>
      </Form>
    );
  }

  return (
    <div className="Signup">
      {newUser === null ? renderForm() : renderConfirmationForm()}
    </div>
  );
}
