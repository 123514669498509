import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Alert from "react-bootstrap/Alert";
import Plyr from "plyr-react";
import "plyr-react/dist/plyr.css";
import "./Videos.css";

export default function Videos() {
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);

  const videoSrc = {
    type: "video",
    title: "Onboarding",
    sources: [
      // {
      //   src: "https://mindpoolpub.s3.amazonaws.com/videos/MindPoolOnboardingMasterWithCaptions.mp4",
      //   //provider: "youtube",
      //   size: 480,
      // },
      {
        src: "https://mindpoolpub.s3.amazonaws.com/videos/MindPoolOnboardingMasterWithCaptions.mp4",
        //provider: "youtube",
        size: 720,
      },
      // {
      //   src: "https://mindpoolpub.s3.amazonaws.com/videos/MindPoolOnboardingMasterWithCaptions.mp4",
      //   //provider: "youtube",
      //   size: 1080,
      // },
    ],
    //quality: { default: 480, options: [720, 480, 1080] },
    ratio: "16:9",
  };

  useEffect(() => {
    async function onLoad() {
      try {
        console.log("Loaded Videos");
      } catch (e) {
        onError(e);
      }
    }

    onLoad();
  }, [isAuthenticated]);

  return (
    <div className="container">
      <div class="container">
        <div class="row">
          <div class="col-sm">
            {" "}
            <div id="player">
              <Plyr source={videoSrc} />
            </div>
          </div>
          <div class="col-sm">
            {" "}
            <Alert variant="warning">
              <h3 className="section-header">3-MONTH PROGRAM</h3>
              <br />
              <h5 className="video-sections">Video Player</h5>
              <br />
              <p>
                <span>&#8226;</span> Recommended using a Desktop Browser.
              </p>
              <p>
                <span>&#8226;</span> Click on the Play button to play the video.
                To view in full{" "}
                <span id="forced-space">
                  screen, click on the button on the buttom right.
                </span>
              </p>
              <p>
                <span>&#8226;</span> Use the controls in the video player to
                adjust speed, replay,{" "}
                <span id="forced-space"> or view a section as desired.</span>
              </p>
              <h5 className="video-sections">Video Sections</h5>
              <br />
              <p>
                <span>&#8226;</span> 00:00 Login and Settings.
              </p>
              <p>
                <span>&#8226;</span> 00:17 Stripe Onboard, Subscribe,
                Dashboards.
              </p>
              <p>
                <span>&#8226;</span> 07:47 Invite Flyer.
              </p>
              <p>
                <span>&#8226;</span> 08:21 Docs{" "}
                <span id="forced-space">08:40 Payout Examples.</span>
              </p>
            </Alert>
          </div>
        </div>
      </div>
    </div>
  );
}
