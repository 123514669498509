import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setSearchFieldsGlobal } from "../../store/interactions";
import { API } from "aws-amplify";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";
import {
  Button,
  Tooltip,
  OverlayTrigger,
  Card,
  Spinner,
} from "react-bootstrap";
import { BsFillEyeFill, BsSearch } from "react-icons/bs";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import { onError } from "../../libs/errorLib";
import "./JSBookmarks.css";

/* data loads */
import { useQuery } from "react-query";
//import { set } from "react-ga";

export default function JSBookmarks() {
  const [isLoading, setIsLoading] = useState(true);
  const { SearchBar } = Search;
  const history = useHistory();

  //Redux state
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  const searchFields = useSelector((state) => state.jsearch.searchFieldsGlobal);

  // const renderSaveTooltip = (props) => (
  //   <Tooltip id="button-tooltip" {...props}>
  //     Bookmark this Search
  //   </Tooltip>
  // );
  const renderViewTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      View Portal
    </Tooltip>
  );
  const renderSetTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Set Current Row as the Search Criteria
    </Tooltip>
  );

  const columns = [
    {
      dataField: "searchText",
      text: "Search Text",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "160px", textAlign: "center" };
      },
    },
    {
      dataField: "city",
      text: "City",
      hidden: true,
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "160px", textAlign: "center" };
      },
    },
    {
      dataField: "usState",
      text: "State",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "90px", textAlign: "center" };
      },
    },
    {
      dataField: "company",
      text: "Company",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "110px", textAlign: "center" };
      },
    },
    {
      dataField: "jobTitle",
      text: "Job Title",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "110px", textAlign: "center" };
      },
    },
    {
      dataField: "expLevel",
      text: "ExpL",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "100px", textAlign: "center" };
      },
    },
    {
      dataField: "jobType",
      text: "Type",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "90px", textAlign: "center" };
      },
    },
    {
      dataField: "radius",
      text: "Radius",
      hidden: true,
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "90px", textAlign: "center" };
      },
    },
    {
      dataField: "datePosted",
      text: "Posted",
      hidden: true,
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "90px", textAlign: "center" };
      },
    },
    {
      dataField: "searchDate",
      text: "Date",
      sort: true,
      order: "desc",
      headerStyle: (colum, colIndex) => {
        return { width: "100px", textAlign: "center" };
      },
      formatter: (cell, row, rowIndex) => {
        //console.log(dayjs(row.searchDate).format('YYYY/MM/DD-HH:mm:ss'));
        return (
          <div className="search-date">
            {dayjs(row.searchDate).format("YYYY/MM/DD HH:mm:ss")}
          </div>
        );
      },
    },
    {
      dataField: "portalName",
      text: "Portal",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "110px", textAlign: "center" };
      },
    },
    {
      text: "Action",
      isDummyField: true,
      headerStyle: (colum, colIndex) => {
        return { width: "140px", textAlign: "center" };
      },
      formatter: (cell, row, rowIndex) => {
        return (
          <div>
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 250, hide: 400 }}
              overlay={renderViewTooltip}
            >
              <Button
                id="link-button"
                variant="outline-secondary"
                size="sm"
                onClick={() => {
                  //console.log (`Product ${rowIndex} of Category ${row.Id} deleted`);
                  console.log(row);
                  window.open(row.portalURL, "_blank");
                }}
              >
                <BsFillEyeFill />
              </Button>
            </OverlayTrigger>
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 250, hide: 400 }}
              overlay={renderSetTooltip}
            >
              <Button
                id="link-button"
                variant="outline-secondary"
                size="sm"
                onClick={() => {
                  //console.log (`Product ${rowIndex} of Category ${row.Id} deleted`);
                  console.log(searchFields);
                  setSearchFieldsGlobal(
                    {
                      search: row.searchText,
                      city: row.city,
                      usState: row.usState,
                      radius: row.radius,
                      company: row.company,
                      title: row.jobTitle,
                      jobType: row.jobType,
                      expLevel: row.expLevel,
                      datePosted: row.datePosted,
                    },
                    dispatch
                  );
                  history.push("/jsearch");
                }}
              >
                <BsSearch />
              </Button>
            </OverlayTrigger>
          </div>
        );
      },
    },
  ];

  // Data Fetch
  const {
    data: bookmarks = [],
    isLoading: isLoadingBookmarks,
    isError,
    error,
    isSuccess,
    refetch,
  } = useQuery(
    ["bookmarks"],
    async () => {
      setIsLoading(true);
      try {
        const bookmarks = await API.get("jobsearches", "/jobsearches");
        setIsLoading(false);
        return bookmarks;
      } catch (error) {
        onError(error);
        return [];
      }
    },
    {
      refetchOnWindowFocus: true,
      retry: true,
      //refetchOnMount: true,
    }
  );

  useEffect(() => {
    async function onLoad() {
      if (!isAuthenticated) {
        return;
      }

      if (bookmarks.length !== 0) {
        return;
      } //fix reload of useEffect

      // try {
      //   //const loadedBookmarks = await loadBookmarks();
      //   //setBookmarks(loadedBookmarks);
      //   setIsLoading(true);
      // } catch (e) {
      //   onError(e);
      // }

      //setIsLoading(false);
    }

    onLoad();
    //console.log(bookmarks);
  }, [bookmarks.length, isAuthenticated]);

  // function loadBookmarks() {
  //   return API.get("jobsearches", "/jobsearches");
  // }

  //console.log(bookmarks);

  // const expandRow = {
  //   renderer: (row, rowIndex) => (
  //     <div>
  //       <p>{ `This Expand row is belong to rowKey ${row.search}` }</p>
  //     </div>
  //   )
  // }

  // const selectRow = {
  //   mode: 'checkbox',
  //   clickToSelect: true,
  //   nonSelectable: nonSelectableRows,
  //   nonSelectableClasses: 'row-index-bigger-than-2101'
  // };

  const latestBookmarks = bookmarks.sort((a, b) => {
    return b.searchDate - a.searchDate;
  });

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );
  const paginationOptions = {
    paginationSize: 10,
    pageStartIndex: 1,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "All",
        value: latestBookmarks.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  function renderLander() {
    return (
      <div className="lander">
        {isLoadingBookmarks ? (
          <div className="container-fluid">
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <div className="container-fluid">
            <h3>Bookmarks</h3>
            <p>No Bookmarks. Use History to add Bookmarks.</p>
          </div>
        )}
      </div>
    );
  }

  function renderBookmarks() {
    return (
      <div className="">
        {/* <BootstrapTable bootstrap4='true' 
            keyField='id' 
            data={ latestPortals } 
            columns={ columns }
            //expandRow={ expandRow }
          /> */}
        <ToolkitProvider
          keyField="id"
          bootstrap4="true"
          data={latestBookmarks}
          columns={columns}
          //selectRow={ selectRow }
          //hiddenRows={ nonSelectableRows }
          search
        >
          {(props) => (
            <div>
              <SearchBar
                {...props.searchProps}
                delay="1"
                className="search-bar"
                placeholder=" Enter a character (Search on any predefined input field)..."
              />
              <Card>
                <Card.Body>
                  <BootstrapTable
                    pagination={paginationFactory(paginationOptions)}
                    {...props.baseProps}
                  />
                </Card.Body>
              </Card>
            </div>
          )}
        </ToolkitProvider>
      </div>
    );
  }

  // Render Bookmarks if not loading

  return (
    <div>
      {bookmarks.length !== 0 && !isLoadingBookmarks ? (
        renderBookmarks()
      ) : (
        <div className="Bookmarks">{renderLander()}</div>
      )}
    </div>
  );
}
