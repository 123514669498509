import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { checkLogin, logout } from "../../store/interactions";
import { useHistory, Link } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import BillingForm from "../../components/BillingForm";
//import { API, Logger } from "aws-amplify";
import { API } from "aws-amplify";
import { Row, Container, Card, Col, Spinner, Alert } from "react-bootstrap";
import { onError, logError } from "../../libs/errorLib";
import config from "../../config";
import "./Marketplace.css";

//Logger.LOG_LEVEL = 'DEBUG'

/* data loads */
//import { useQuery } from "react-query";
import { GetUserProfiles } from "../../state/data/userProfiles";

export default function Marketplace() {
  const history = useHistory();

  // Redux
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  // const user = useSelector((state) => state.user.userinfo);
  // config.isDemo && console.log("User Info via Redux: ", user);

  // Set from Redux
  // const userProfile = user;
  // const userEmail = user.userEmail;
  // const stripeAccountId = user.stripeAccountId;
  // const stripeCustomerId = user.stripeCustomerId;
  // const connectOnboarded = user.connectOnboarded;
  // const connectOnboardedDate = user.connectOnboardedDate;
  // const fullyOnboarded = user.fullyOnboarded;
  // const sponsorEmail = user.sponsorEmail;
  // const stripeLoginLink = user.stripeLoginLink;
  // const sponsorStripeAccountId = user.sponsorStripeAccountId;
  // const sponsorPaid = user.sponsorPaid;

  // Data Fetch using a higher order component
  const {
    userProfiles,
    isFetchingUserProfiles,
    isLoadingUserProfiles,
    isSuccess,
    refetch,
  } = GetUserProfiles();
  config.isDemo &&
    console.log("Data from GetUserProfiles in Home-js: ", userProfiles);

  // Global Vars
  let userProfile,
    userEmail,
    stripeAccountId,
    stripeCustomerId,
    connectOnboarded,
    connectOnboardedDate,
    fullyOnboarded,
    sponsorEmail,
    stripeLoginLink,
    sponsorStripeAccountId,
    sponsorPaid;

  // Set from React Query
  if (userProfiles && isSuccess) {
    userProfile = userProfiles[0];
    userEmail = userProfiles[0].userEmail;
    stripeAccountId = userProfiles[0].stripeAccountId;
    stripeCustomerId = userProfiles[0].stripeCustomerId;
    connectOnboarded = userProfiles[0].connectOnboarded;
    connectOnboardedDate = userProfiles[0].connectOnboardedDate;
    fullyOnboarded = userProfiles[0].fullyOnboarded;
    sponsorEmail = userProfiles[0].sponsorEmail;
    stripeLoginLink = userProfiles[0].stripeLoginLink;
    sponsorStripeAccountId = userProfiles[0].sponsorStripeAccountId;
    sponsorPaid = userProfiles[0].sponsorPaid;
  }

  // Loading state
  const [isLoading, setIsLoading] = useState(false);
  // Stripe and payments related
  //console.log("Stripe Key: ", config.STRIPE_KEY);
  const stripePromise = loadStripe(config.STRIPE_KEY);
  const [is10minsSinceConnectOnboarding, setIs10minsSinceConnectOnboarding] =
    useState(false);
  const [connectAccountIsInReview, setConnectAccountIsInReview] =
    useState(false);

  useEffect(() => {
    function updateProfile(profile) {
      if (config.isDemo) {
        console.log("What is being passed to updateProfile", userProfile);
      }
      return API.put(
        "userprofiles",
        `/userprofiles/${userProfile.userprofileId}`,
        {
          body: profile,
        }
      );
    }

    async function onLoad() {
      if (!isAuthenticated) {
        return;
      }

      setIsLoading(true);
      // Calculate 1 hour after connectOnboard using connectOnboardedDate
      const today = Math.floor(new Date().getTime() / 1000);
      const tenMinsAfterConnectOnboard = connectOnboardedDate + 600;

      if (config.isDemo) {
        console.log("Today: ", today);

        console.log(
          "Marketplace-JS - Connect Onboard Date: ",
          connectOnboardedDate
        );
        console.log(
          "Marketplace-JS - Ten mins after connectOnboard: ",
          tenMinsAfterConnectOnboard
        );

        console.log(
          "10 mins after connectOnboard date: ",
          tenMinsAfterConnectOnboard
        );
      }

      // Do the 10 mins check and also only if there is a stripe Account Id
      if (today > tenMinsAfterConnectOnboard) {
        if (stripeAccountId) {
          try {
            const userStripeAccountInfo = await getStripeAccountInfo(
              stripeAccountId
            );

            if (config.isDemo) {
              console.log(
                "User Stripe Account Info: ",
                userStripeAccountInfo.account
              );
            }

            // This is the weird case where the user has a stripe account but the account is in review in midstream
            // Somehow the event system updated connectOnboarded as true but the account is still in restricted state
            if (
              !userStripeAccountInfo.account.charges_enabled &&
              !userStripeAccountInfo.account.payouts_enabled
            ) {
              if (config.isDemo) {
                console.log(
                  "Marketplace.js-Something happened. Account is restricted even after connectOnboard is true in userprofile!"
                );
                console.log(
                  "EXECUTING BRUTE FORCE WORK AROUND - update connectOnboard to false"
                );
              }
              //const userProfiles = await loadUserProfiles();
              const setConnectOnboarded = await updateProfile({
                ...userProfile,
                connectOnboarded: false,
              });
              if (config.isDemo) {
                console.log("setConnectOnboarded: ", setConnectOnboarded);
              }
            }

            // Semi-restrictive case where the account is in review
            if (
              userStripeAccountInfo.account.charges_enabled &&
              !userStripeAccountInfo.account.payouts_enabled
            ) {
              setConnectAccountIsInReview(true);
            } else {
              setConnectAccountIsInReview(false);
            }

            // This is the normal case where the account is not restricted
            if (
              userStripeAccountInfo.account.charges_enabled &&
              userStripeAccountInfo.account.payouts_enabled
            ) {
              setIs10minsSinceConnectOnboarding(true);
            } else {
              setIs10minsSinceConnectOnboarding(false);
            }
          } catch (e) {
            logError(e);
            onError(e);
          }
        }
      }

      if (config.isDemo) {
        console.log(
          "Marketplace.js-Sponsor Stripe Connect ID: ",
          sponsorStripeAccountId
        );
        console.log(
          "Marketplace.js-Is it 10 mins after connectOnboard?: ",
          is10minsSinceConnectOnboarding
        );
        console.log("Marketplace.js-Stripe Connect ID: ", stripeAccountId);
        console.log("Marketplace.js-Stripe Customer ID: ", stripeCustomerId);
        console.log(
          "Marketplace.js-Has Connect Onboarded?: ",
          connectOnboarded
        );
        console.log("Marketplace.js-Has Fully Onboarded?: ", fullyOnboarded);
        console.log("Marketplace.js-Has Sponsor Paid?: ", sponsorPaid);
        console.log("Marketplace.js-StripeLoginLink: ", stripeLoginLink);
      }

      setIsLoading(false);
    }

    onLoad();
  }, [
    isAuthenticated,
    stripeCustomerId,
    stripeAccountId,
    sponsorStripeAccountId,
    sponsorEmail,
    sponsorPaid,
    fullyOnboarded,
    connectOnboarded,
    connectOnboardedDate,
    is10minsSinceConnectOnboarding,
    stripeLoginLink,
    userProfile,
  ]);

  // function getSpecialSponsor() {
  //   if (specialSponsors.includes(sponsorEmail)) {
  //     return sponsorEmail;
  //   }
  // }

  // function loadUserProfiles() {
  //   return API.get("userprofiles", "/userprofiles");
  // }

  function saveProfile(profile) {
    console.log("Save Profile, what is being passed: ", profile);
    return API.put(
      "userprofiles",
      `/userprofiles/${userProfile.userprofileId}`,
      {
        body: profile,
      }
    );
  }

  function getStripeAccountInfo(accountId) {
    return API.post("userprofiles", "/marketplace/get-account-info", {
      body: {
        accountId: accountId,
      },
    });
  }

  function createHostedAccount(email) {
    return API.post("userprofiles", "/marketplace/create-hosted-account", {
      body: {
        email: email,
      },
    });
  }

  function createHostedAccountLinks(stripeAccountId) {
    return API.post(
      "userprofiles",
      "/marketplace/create-hosted-account-links",
      {
        body: {
          stripeAccountId: stripeAccountId,
        },
      }
    );
  }

  async function openStripeOnboarding() {
    setIsLoading(true);
    console.log("Clicked Open Stripe Onboarding");
    //console.log(userEmail);

    // Continue onboarding if there is already a stripeAccountId
    if (stripeAccountId) {
      try {
        const stripeHostedAccountLinks = await createHostedAccountLinks(
          stripeAccountId
        );
        //await checkLogin(dispatch);
        //await Auth.signOut();
        await logout(dispatch); //Logout the user
        setIsLoading(false);
        window.open(stripeHostedAccountLinks.accountLinks.url, "_self");
      } catch (e) {
        onError(e);
        logError(e);
      }
    } else {
      const stripeHostedAccount = await createHostedAccount(userEmail);
      //console.log(stripeHostedAccount);
      if (stripeHostedAccount) {
        try {
          const profileSave = await saveProfile({
            ...userProfile,
            connectOnboarded: false,
            stripeAccountId: stripeHostedAccount.account.id,
          });
          if (config.isDemo) {
            console.log(
              "profileSave state after clicking onboard: ",
              profileSave
            );
          }
          if (profileSave) {
            try {
              const stripeHostedAccountLinks = await createHostedAccountLinks(
                stripeHostedAccount.account.id
              );
              //await checkLogin(dispatch);
              //await Auth.signOut();
              await logout(dispatch); //Logout the user
              setIsLoading(false);
              //userHasAuthenticated(false);
              window.open(stripeHostedAccountLinks.accountLinks.url, "_self");
            } catch (e) {
              onError(e);
              logError(e);
            }
          }
        } catch (e) {
          onError(e);
          logError(e);
        }
      }
    }
  }

  async function openPayoutsPortal() {
    setIsLoading(true);
    window.open(stripeLoginLink, "_target");
    setIsLoading(false);
    history.push("/");
    // try {
    //   //Force logout user, until we can have a single tab and real-time update to global state
    //   await Auth.signOut();
    //   userHasAuthenticated(false);
    //   sessionStorage.clear()
    // } catch (e) {
    //   onError(e);
    // }
  }

  function createCustomerLoginLink(customerId) {
    return API.post("userprofiles", "/marketplace/create-customer-login-link", {
      body: {
        customerId: customerId,
      },
    });
  }

  async function openStripeCustomerPortal() {
    setIsLoading(true);
    //console.log("Clicked Open Stripe Customer Portal");
    //console.log(userEmail);

    if (stripeCustomerId) {
      const loginLink = await createCustomerLoginLink(stripeCustomerId);
      //console.log(loginLink.loginLink.url);
      if (loginLink) {
        window.open(loginLink.loginLink.url, "_self");
      }
    }

    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    //history.push("/");

    // try {
    //   //Force logout user, until we can have a single tab and real-time update to global state
    //   await Auth.signOut();
    //   userHasAuthenticated(false);
    //   sessionStorage.clear();
    // } catch (e) {
    //   onError(e);
    // }
  }

  function renderForm() {
    return (
      <>
        {fullyOnboarded ? (
          <Alert variant="success">
            You have successfully subscribed and activated Solutions.{" "}
          </Alert>
        ) : (
          <></>
        )}
        <div className="org" spellCheck="false">
          <div className="header">
            <div className="info">
              <h4 className="marketplace-header">
                {connectOnboarded &&
                !fullyOnboarded &&
                is10minsSinceConnectOnboarding ? (
                  <span>
                    {" "}
                    3-MONTH PROGRAM{" "}
                    <span className="marketplace-header-subtitle-lowercase">
                      {" "}
                      for{" "}
                    </span>
                    US RESIDENTS{" "}
                    <span className="marketplace-header-subtitle">
                      [ Explore, Invite, Mentor,{" "}
                      <span className="mid-stream-text-lowercase">And </span>
                      Earn ]
                    </span>{" "}
                  </span>
                ) : (
                  <span>
                    {" "}
                    3-MONTH PROGRAM{" "}
                    {/* <span className="marketplace-header-subtitle-lowercase">
                      {" "}
                      for{" "}
                    </span>
                    US RESIDENTS{" "} */}
                    <span className="marketplace-header-subtitle">
                      [ Explore, Invite, Mentor,{" "}
                      <span className="mid-stream-text-lowercase">And </span>
                      Earn ]
                    </span>{" "}
                  </span>
                )}
              </h4>
            </div>
            {connectOnboarded &&
            !fullyOnboarded &&
            is10minsSinceConnectOnboarding &&
            !connectAccountIsInReview &&
            !sponsorEmail === "jmj@mindpoollabs.net" ? (
              <div>
                {/* <a
                  className="LoaderButton btn btn-warning pull-right"
                  href="https://docs.mindpool.io/docs/invite"
                  target="_blank"
                  rel="noreferrer"
                >
                  Invite
                </a> */}
                {/* <a
                  className="LoaderButton btn btn-warning pull-right"
                  href="https://bit.ly/3kotrLU"
                  target="_blank"
                  rel="noreferrer"
                >
                  Invite Flyer
                </a> */}
                <Link
                  className="LoaderButton btn btn-warning"
                  to=""
                  onClick={
                    stripeLoginLink
                      ? (e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          openPayoutsPortal();
                        }
                      : openStripeOnboarding
                  }
                >
                  Payouts Portal
                </Link>
              </div>
            ) : (
              <></>
            )}
            {connectOnboarded &&
            !fullyOnboarded &&
            !connectAccountIsInReview &&
            !sponsorEmail === "jmj@mindpoollabs.net" &&
            is10minsSinceConnectOnboarding ? (
              <div>
                <a
                  className="LoaderButton btn btn-warning pull-right"
                  href="https://docs.mindpool.io/wp-content/uploads/2022/05/Credit-Card-Details-B.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Payment Instructions
                </a>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="AppListPanel-marketplace">
            <Container fluid>
              <Row>
                <Col>
                  <Card>
                    <Card.Body>
                      <Card.Title className="marketplace-header"></Card.Title>
                      <Card.Text>
                        {connectOnboarded &&
                        !is10minsSinceConnectOnboarding &&
                        !connectAccountIsInReview &&
                        !fullyOnboarded ? (
                          <div>
                            <Alert variant="warning">
                              <ol>
                                <li>
                                  Congratulations! You have successfully
                                  provided the required information to open an
                                  account with Stripe.
                                </li>
                                <li>
                                  You will receive an email from Stripe if any
                                  additional information is required.
                                </li>
                                <li>
                                  Stripe requires up to 48 hours or more to
                                  verify your personal information.
                                </li>
                                <li>
                                  Please wait and check back after at least 10
                                  minutes to continue enrollment.
                                </li>
                              </ol>
                            </Alert>
                            <Link
                              className="LoaderButton btn btn-warning"
                              to="/"
                            >
                              Home
                            </Link>
                          </div>
                        ) : (
                          <></>
                        )}
                        {!connectOnboarded ? (
                          <div>
                            <Alert variant="warning">
                              <div>
                                <ol>
                                  <li>
                                    The subscription includes access to
                                    Simplified Job Search (SJS), and Notes with
                                    Chrome Extension.
                                  </li>
                                  <li>
                                    The Solutions help anyone efficiently find
                                    and land a job.
                                  </li>
                                  <li>
                                    In addition to Free Access, Subscribers have
                                    the opportunity to recover the subscription
                                    fee by inviting other (paid) Users.
                                  </li>
                                  <li>
                                    In order to manage payouts (of such fees),
                                    the User is required to onboard with our
                                    partner Stripe.
                                  </li>
                                  <li>
                                    Prior to clicking on Onboard, please watch
                                    the video and/or review onboarding
                                    instructions.
                                  </li>
                                  <li>
                                    Once you click on Onboard, you will be
                                    logged out and will be transferred to the
                                    Stripe portal to open an account.
                                  </li>
                                  <li>
                                    Once you have completed the process, you
                                    will be directed to a payment screen.
                                  </li>
                                  <li>
                                    Pay $30 / month for 3 months to activate
                                    SJS, and Notes with Chrome Extension
                                    Solutions.
                                  </li>
                                </ol>
                              </div>
                            </Alert>
                            {!connectOnboarded ? (
                              <>
                                <Link
                                  className="LoaderButton btn btn-warning"
                                  to="/videos"
                                >
                                  Video
                                </Link>
                                <a
                                  className="LoaderButton btn btn-warning pull-right"
                                  href="https://docs.mindpool.io/docs/payouts"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  Onboarding Instructions
                                </a>
                              </>
                            ) : (
                              <></>
                            )}
                            <Link
                              className="LoaderButton btn btn-warning pull-right"
                              onClick={(e) => {
                                e.preventDefault();
                                openStripeOnboarding();
                              }}
                            >
                              Onboard
                            </Link>
                          </div>
                        ) : (
                          <></>
                        )}
                        {fullyOnboarded ? (
                          <Alert variant="warning">
                            <ol>
                              <li>
                                Click on Billing Portal to view your
                                subscription.
                              </li>
                              <li>
                                {" "}
                                Click on Payouts Portal to update personal
                                information and to view payouts.
                              </li>
                            </ol>
                          </Alert>
                        ) : (
                          <></>
                        )}
                        {connectOnboarded &&
                        !fullyOnboarded &&
                        is10minsSinceConnectOnboarding &&
                        !connectAccountIsInReview &&
                        sponsorEmail === "jmj@mindpoollabs.net" ? (
                          <div className="container">
                            <div className="Home">
                              <div className="">
                                <Alert variant="warning">
                                  <ol>
                                    <li>
                                      You are participating as a free user.
                                    </li>
                                    <li>
                                      Start receiving payouts from the 4th
                                      invited subscribed user.
                                    </li>
                                    <li>
                                      Please click on the payouts portal button
                                      to view your account.
                                    </li>
                                  </ol>
                                </Alert>
                                <Link
                                  className="LoaderButton btn btn-warning"
                                  to=""
                                  onClick={
                                    stripeLoginLink
                                      ? (e) => {
                                          e.preventDefault();
                                          e.stopPropagation();
                                          openPayoutsPortal();
                                        }
                                      : openStripeOnboarding
                                  }
                                >
                                  Payouts Portal
                                </Link>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                        {connectOnboarded &&
                        !fullyOnboarded &&
                        is10minsSinceConnectOnboarding &&
                        sponsorEmail !== "jmj@mindpoollabs.net" ? (
                          <div className="AppListPanel-marketplace">
                            <Elements
                              stripe={stripePromise}
                              fonts={[
                                {
                                  cssSrc:
                                    "https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800",
                                },
                              ]}
                            >
                              {sponsorStripeAccountId !== null ? (
                                <BillingForm
                                  sponsorStripeAccountId={
                                    sponsorStripeAccountId
                                  }
                                  customerId={userProfile.stripeCustomerId}
                                  stripeAccountId={userProfile.stripeAccountId}
                                  userprofileId={userProfile.userprofileId}
                                  userFirstName={userProfile.userFirstName}
                                  userLastName={userProfile.userLastName}
                                  userEmail={userProfile.userEmail}
                                  subscriptionCount={
                                    userProfile.subscriptionCount
                                  }
                                  sponsorEmail={userProfile.sponsorEmail}
                                  sponsorPaid={sponsorPaid}
                                  connectOnboarded={
                                    userProfile.connectOnboarded
                                  }
                                  fullyOnboarded={userProfile.fullyOnboarded}
                                  userProfile={userProfile}
                                />
                              ) : (
                                <></>
                              )}
                            </Elements>
                          </div>
                        ) : (
                          <></>
                        )}
                        {connectOnboarded &&
                        connectAccountIsInReview &&
                        !fullyOnboarded &&
                        !is10minsSinceConnectOnboarding ? (
                          <div>
                            <Alert variant="warning">
                              <ol>
                                <li>
                                  Stripe has not completed approval for payouts
                                  for your account.
                                </li>
                                <li>
                                  You may have received an email from Stripe if
                                  any additional information is required.
                                </li>
                                <li>
                                  Login to the stripe portal using the link
                                  provided in the email or clicking on the
                                  button below to provide required information.
                                </li>
                                <li>
                                  You may also contact Stripe directly by
                                  replying to the email with any questions.
                                </li>
                              </ol>
                            </Alert>
                            <Link
                              className="LoaderButton btn btn-warning"
                              to="/"
                            >
                              Home
                            </Link>
                            <Link
                              className="LoaderButton btn btn-warning"
                              to=""
                              // onClick={
                              //   stripeLoginLink
                              //     ? (e) => {
                              //         e.preventDefault();
                              //         e.stopPropagation();
                              //         openPayoutsPortal();
                              //       }
                              //     : openStripeOnboarding
                              // }
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                openStripeOnboarding();
                              }}
                            >
                              Payouts Portal
                            </Link>
                          </div>
                        ) : (
                          <></>
                        )}
                        {fullyOnboarded ? (
                          <div>
                            <a
                              className="LoaderButton btn btn-warning pull-right"
                              href="https://mindpoolpub.s3.amazonaws.com/MindPool-3-Month-Program-Flyer-Instructions.pdf"
                              target="_blank"
                              rel="noreferrer"
                            >
                              Invite
                            </a>
                            {/* <a
                              className="LoaderButton btn btn-warning pull-right"
                              href="https://bit.ly/3kotrLU"
                              target="_blank"
                              rel="noreferrer"
                            >
                              Invite Flyer
                            </a> */}
                            <Link
                              className="LoaderButton btn btn-warning pull-right"
                              to=""
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                openStripeCustomerPortal();
                              }}
                            >
                              Billing Portal
                            </Link>
                            <Link
                              className="LoaderButton btn btn-warning"
                              to=""
                              onClick={
                                stripeLoginLink
                                  ? (e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      openPayoutsPortal();
                                    }
                                  : openStripeOnboarding
                              }
                            >
                              Payouts Portal
                            </Link>
                          </div>
                        ) : (
                          <></>
                        )}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </>
    );
  }

  function renderSpinner() {
    return (
      <div className="container-fluid lander">
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <div className="marketplace">
      {!isLoading && !isLoadingUserProfiles ? renderForm() : renderSpinner()}
    </div>
  );
}
