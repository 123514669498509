import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

/* Import Reducers */
//import { provider, tokens, exchange, user } from './reducers';
import { user, jsearch } from "./reducers";

const reducer = combineReducers({
  user,
  jsearch,
});

const initialState = {};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
