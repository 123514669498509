import { Storage } from "aws-amplify";
import { onError } from "./errorLib";

export async function s3Upload(file) {
  const filename = `${Date.now()}-${file.name}`;

  const stored = await Storage.vault.put(filename, file, {
    contentType: file.type,
  });

  return stored.key;
}


export async function s3Remove(file) {
  // Storage.remove(file, { level: 'private' })
  //   .then(result => console.log(result))
  //   .catch(err => console.log(err));
  
  try {
    await Storage.remove(file, { level: 'private' });
    console.log('file delete invoked!');  
  } catch (e) {
    onError(e);
    console.log(e);
  }

}