import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Auth, API } from "aws-amplify";
import { login, setCurrentUserGroup } from "../store/interactions";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import LoaderButton from "../components/LoaderButton";
import { useFormFields } from "../libs/hooksLib";
import { onError } from "../libs/errorLib";
import config from "../config";
import "./Login.css";
import { a } from "aws-amplify";
//import config from "../config";

/* data loads */
//import { useQuery } from "react-query";
//import { GetUserProfiles } from "../state/data/userProfiles";

export default function Login() {
  const [isLoading, setIsLoading] = useState(false);
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: "",
    confirmationCode: "",
  });

  const initialUserProfile = {
    userFirstName: "",
    userLastName: "",
    userEmail: fields.email,
    sponsorEmail: "user#" + fields.email, // need this to be unique as we are using a global index where sponsorEmail is the partition key
    inviteCode: "",
    inviteCodeIsActive: false,
    authorizeCode: "",
    authorizeCodeIsValid: false,
    paidToSponsorAmount: "",
    paidUserCount: 0,
    invitedUserCount: 0,
    totalPaymentsCount: 0,
    totalPayoutsCount: 0,
    totalDisbursements: 0,
    totalRemainingBalance: 0,
    invitedUsers: [],
    stripeLoginLink: "",
    stripePaymentsData: [],
    stripePayoutsData: [],
    accumulatedTokens: 0,
    disbursedTokens: 0,
    walletAddress: "",
    nonUSResidentsToSAccepted: false,
    stripeInitialDataSeeded: false,
    fullyOnboarded: false,
    connectOnboarded: false,
    connectOnboardedDate: 0,
    stripeAccountId: "",
    stripeCustomerd: "",
    subscriptionId: "",
    rewardsEnrolledDate: 0,
    rewardsRenewalDate: 0,
    subscriptionTier: "free",
    subscriptionCount: "",
  };

  //Redux state
  const dispatch = useDispatch();
  const isRequiredConfirmation = useSelector((state) => state.user.isRequired);
  const userLocation = useSelector((state) => state.user.currentUserLocation);

  // Data Fetch using a higher order component
  //const { refetch } = GetUserProfiles();
  //config.isDemo && console.log("Data from GetUserProfiles in Login-js");

  function validateForm() {
    return fields.email.length > 0 && fields.password.length > 0;
  }

  function validateConfirmationForm() {
    return fields.confirmationCode.length > 0;
  }

  function createUserProfile(userprofile) {
    return API.post("userprofiles", "/userprofiles", {
      body: userprofile,
    });
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setIsLoading(true);
    await login(fields.email, fields.password, dispatch);
    await setCurrentUserGroup(dispatch);
    //await refetch();
    setIsLoading(false);
  }

  async function handleConfirmationSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      const confirmSignup = await Auth.confirmSignUp(
        fields.email,
        fields.confirmationCode
      );
      console.log("confirmSignup", confirmSignup);
      if (confirmSignup === "SUCCESS") {
        await login(fields.email, fields.password, dispatch);
        await setCurrentUserGroup(dispatch);
        const userProfile = await createUserProfile(initialUserProfile);
        if (userProfile) {
          config.isDemo && console.log(initialUserProfile);
          history.push("/");
        }
      } else {
        try {
          const deleteUser = await Auth.deleteUser();
          console.log("deleteUser", deleteUser);
        } catch (error) {
          onError(e);
          console.log("Error deleting user", error);
        }
      }
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }

  function renderConfirmationForm() {
    return (
      <Form onSubmit={handleConfirmationSubmit}>
        <Form.Group size="lg" controlId="email">
          <Form.Label>Email</Form.Label>
          <Form.Control
            autoFocus
            type="email"
            value={fields.email}
            onChange={handleFieldChange}
          />
        </Form.Group>
        <Form.Group size="lg" controlId="password">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            value={fields.password}
            onChange={handleFieldChange}
          />
        </Form.Group>
        <Form.Group controlId="confirmationCode" size="lg">
          <Form.Label>Confirmation Code</Form.Label>
          <Form.Control
            autoFocus
            type="tel"
            onChange={handleFieldChange}
            value={fields.confirmationCode}
          />
          <span>You have not entered a confirmation code during signup</span>
          <Form.Text muted>
            Please check your email for the code and click Verify
          </Form.Text>
        </Form.Group>
        <LoaderButton
          block
          size="lg"
          type="submit"
          variant="success"
          isLoading={isLoading}
          disabled={!validateConfirmationForm()}
        >
          Verify
        </LoaderButton>
      </Form>
    );
  }

  function renderLoginForm() {
    return (
      <div className="Login">
        <Form onSubmit={handleSubmit}>
          <Form.Group size="lg" controlId="email">
            <Form.Label>Email</Form.Label>
            <Form.Control
              autoFocus
              type="email"
              value={fields.email}
              onChange={handleFieldChange}
            />
          </Form.Group>
          <Form.Group size="lg" controlId="password">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              value={fields.password}
              onChange={handleFieldChange}
            />
          </Form.Group>
          <Link to="/login/reset">Forgot password?</Link>
          <LoaderButton
            block
            size="lg"
            type="submit"
            variant="msteams"
            isLoading={isLoading}
            disabled={!validateForm()}
          >
            Login
          </LoaderButton>
        </Form>
      </div>
    );
  }

  return (
    <div className="Signup">
      {isRequiredConfirmation ? renderConfirmationForm() : renderLoginForm()}
    </div>
  );
}
