import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Route, Switch } from "react-router-dom";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import Home from "./containers/Home";
import Settings from "./containers/Settings";
import ResetPassword from "./containers/ResetPassword";
import Login from "./containers/Login";
import Signup from "./containers/Signup";
import NotFound from "./containers/NotFound";
import JSHome from "./containers/jsearch/JSHome";
import History from "./containers/jsearch/JSHistory";
import Bookmarks from "./containers/jsearch/JSBookmarks";
import NewNote from "./containers/notes/NewNote";
import Notes from "./containers/notes/Notes";
import NSHome from "./containers/notes/NSHome";
import Marketplace from "./containers/marketplace/Marketplace";
import GlobalMarketplace from "./containers/globalmarketplace/GlobalMarketplace";
import Videos from "./containers/videos/Videos";
import config from "./config";

import { setCurrentUserLocation } from "./store/interactions";

export default function Routes() {
  const dispatch = useDispatch();
  const [isDemo, setIsDemo] = useState(false);
  useEffect(() => {
    async function onLoad() {
      if (config.isDemo) {
        setIsDemo(true);
      }
      await setCurrentUserLocation(dispatch);
    }

    onLoad();
  }, [dispatch]);

  return (
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>
      <UnauthenticatedRoute exact path="/login">
        <Login />
      </UnauthenticatedRoute>
      <AuthenticatedRoute exact path="/settings">
        <Settings />
      </AuthenticatedRoute>
      <UnauthenticatedRoute exact path="/login/reset">
        <ResetPassword />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute exact path="/signup">
        <Signup />
      </UnauthenticatedRoute>
      <AuthenticatedRoute exact path="/jsearch">
        <JSHome />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/jsearch/history">
        <History />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/jsearch/bookmarks">
        <Bookmarks />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/notes">
        <NSHome />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/notes/new">
        <NewNote />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/notes/:id">
        <Notes />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/videos">
        <Videos />
      </AuthenticatedRoute>
      {/* {isDemo ? (
        <AuthenticatedRoute exact path="/marketplace">
          <Marketplace />
        </AuthenticatedRoute>
      ) : (
        <AuthenticatedRoute exact path="/cdab66d6-5123-11ec-bf63-0242ac130002">
          <Marketplace />
        </AuthenticatedRoute>
      )} */}
      <AuthenticatedRoute exact path="/marketplace">
        <Marketplace />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/globalmarketplace">
        <GlobalMarketplace />
      </AuthenticatedRoute>
      {/* Finally, catch all unmatched routes */}
      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
}
