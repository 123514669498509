import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setPortals, setSearchFieldsGlobal } from "../../store/interactions";
import genUUID from "../../utils/genUUID";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import CardGroup from "react-bootstrap/CardGroup";
import ListGroup from "react-bootstrap/ListGroup";
import ListGroupItem from "react-bootstrap/ListGroupItem";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import "./JSHome.css";
import config from "../../config";

export default function JSHome() {
  const excludedExpLevelPortals = [
    "CareerBuilder",
    "Dice",
    "LinkUp",
    "Monster",
    "SimplyHired",
    "Ziprecruiter",
  ];

  //Redux state
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  const portals = useSelector((state) => state.jsearch.portals);
  const searchFieldsGlobal = useSelector(
    (state) => state.jsearch.searchFieldsGlobal
  );

  //console.log("SearchFieldsGlobal: ", searchFieldsGlobal);

  // Local State used by Forms
  // Set initial state for search fields to be empty if not already set in Redux
  const [searchFields, setSearchFields] = useState(() => {
    if (Object.entries(searchFieldsGlobal).length !== 0) {
      return searchFieldsGlobal;
    } else {
      return {
        search: "",
        city: "",
        usState: "",
        company: "",
        title: "",
        jobType: "",
        expLevel: "",
        radius: "",
        datePosted: "",
      };
    }
  });

  useEffect(() => {
    //console.log(portals);
    //config.isDemo && console.log("Current Search Fields: ", searchFields);
    setSearchFieldsGlobal(searchFields, dispatch);
  }, [searchFields, portals, dispatch]);

  // Utility Functions

  function clearFields() {
    setSearchFields({
      search: "",
      city: "",
      usState: "",
      company: "",
      title: "",
      jobType: "",
      expLevel: "",
      radius: "",
      datePosted: "",
    });
  }
  // Global state

  var portalName;
  var searchURL;
  const addPortal = () => {
    setPortals(
      {
        id: genUUID(),
        //search: searchFields.search === "Job" ? "" : searchFields.search,
        search: searchFields.search,
        city: searchFields.city,
        usState: searchFields.usState,
        radius: searchFields.radius,
        company: searchFields.company,
        title: searchFields.title,
        jobType: searchFields.jobType,
        expLevel:
          excludedExpLevelPortals.indexOf(portalName) === -1
            ? searchFields.expLevel
            : "",
        datePosted: searchFields.datePosted,
        searchDate: Date.now(),
        portalName,
        searchURL,
      },
      dispatch
    );
    //console.log(JSON.stringify(portals, null, 2))
  };

  function handleUndefined(value) {
    return value === undefined ? "" : value;
  }

  function handleNaN(value) {
    return isNaN(value) ? "" : value;
  }

  // function handleEmptySearch(value) {
  //   return value !== ""
  //     ? (searchFields["search"] = "Job")
  //     : searchFields.search;
  // }

  function handleSearchCareerBuilder() {
    console.log("clicked CB Search");
    console.log(searchFields);

    // Set local variables for CareerBuilder
    let cbJobType = "";
    let cbTitle = "";
    let cbSearch = "";
    let cityState = "US";
    let outRadius = "";

    switch (searchFields.jobType) {
      case "Full Time":
        cbJobType = "jtft%2Cjtfp";
        break;
      case "Part Time":
        cbJobType = "jtpt%2Cjtfp";
        break;
      case "Contract":
        cbJobType = "jtct%2Cjtc2%2Cjtcc";
        break;
      case "Contract to Hire":
        cbJobType = "jtch";
        break;
      case "Temporary":
        cbJobType = "jtse%2Cjttf%2Cjttp";
        break;
      default:
        cbJobType = "";
    }

    console.log(searchFields.jobType);

    // Set Radius

    switch (searchFields.radius) {
      case "Exact Location":
      case "5 Mile Radius":
        outRadius = "5";
        break;
      case "10 Mile Radius":
        outRadius = "10";
        break;
      case "15 Mile Radius":
      case "20 Mile Radius":
      case "25 Mile Radius":
      case "30 Mile Radius":
        outRadius = "30";
        break;
      case "50 Mile Radius":
        outRadius = "50";
        break;
      default:
        outRadius = "";
    }

    console.log(searchFields.radius);

    // Set Date Posted

    let outDatePosted = "";

    switch (searchFields.datePosted) {
      case "Last 24 hours":
        outDatePosted = "1";
        break;
      case "Last 3 days":
        outDatePosted = "3";
        break;
      case "Last 7 days":
        outDatePosted = "7";
        break;
      case "Last 14 days":
      case "Last 30 days":
        outDatePosted = "30";
        break;
      default:
        outDatePosted = "";
    }
    console.log(searchFields.datePosted);

    // Concatenate City and State

    if (searchFields.city && searchFields.usState) {
      cityState = searchFields.city + "%2C%20" + searchFields.usState;
    } else if (searchFields.usState) {
      cityState = searchFields.usState;
    } else if (searchFields.city) {
      cityState = searchFields.city;
    } else {
      cityState = "";
    }

    if (searchFields.search && searchFields.title) {
      cbSearch = searchFields.title + "%20" + searchFields.search;
    } else if (searchFields.search) {
      cbSearch = searchFields.search;
    } else if (searchFields.title) {
      //cbTitle = "title%3A" + searchFields.title;
      cbTitle = searchFields.title;
    } else {
      cbTitle = "";
    }

    // if (!searchFields.company) {
    //   searchFields.company = "";
    // }

    // Clean up search string
    const searchStringValues = [
      cbTitle,
      cbSearch,
      cityState,
      searchFields.company,
      cbJobType,
      outRadius,
      outDatePosted,
    ];

    searchStringValues.forEach((value) => {
      handleNaN(value);
      handleUndefined(value);
    });

    let searchString;

    searchString =
      "https://www.careerbuilder.com/jobs?" +
      "&keywords=" +
      cbTitle +
      cbSearch +
      "&location=" +
      cityState +
      "&company_name=" +
      searchFields.company +
      "&emp=" +
      cbJobType +
      "&radius=" +
      outRadius +
      "&posted=" +
      outDatePosted;

    window.open(searchString, "_blank");
    portalName = "CareerBuilder";
    searchURL = searchString;
    addPortal();
  }

  function handleSearchDice() {
    console.log("clicked Dice Search");

    // Set jobType for Dice

    let diceJobType = "";
    let diceDatePosted = "";
    let outRadius = "";
    let diceSearch = "";
    let diceTitle = "";
    let cityState = "";

    switch (searchFields.jobType) {
      case "Full Time":
        diceJobType = "FULLTIME";
        break;
      case "Part Time":
        diceJobType = "PARTTIME";
        break;
      case "Contract":
        diceJobType = "CONTRACT";
        break;
      case "Third Party":
        diceJobType = "THIRD_PARTY";
        break;
      default:
        diceJobType = "";
    }
    console.log(searchFields.jobType);

    // Set Date Posted

    switch (searchFields.datePosted) {
      case "Last 24 hours":
        diceDatePosted = "ONE";
        break;
      case "Last 3 days":
        diceDatePosted = "THREE";
        break;
      case "Last 7 days":
        diceDatePosted = "SEVEN";
        break;
      default:
        diceDatePosted = "";
    }
    console.log(searchFields.datePosted);

    // Set Radius for Dice

    switch (searchFields.radius) {
      case "Exact Location":
        outRadius = "0";
        break;
      case "5 Mile Radius":
        outRadius = "5";
        break;
      case "10 Mile Radius":
        outRadius = "10";
        break;
      case "15 Mile Radius":
        outRadius = "15";
        break;
      case "25 Mile Radius":
        outRadius = "25";
        break;
      case "50 Mile Radius":
        outRadius = "50";
        break;
      default:
        outRadius = "";
    }
    console.log(searchFields.radius);

    // Concatenate City and State
    if (searchFields.city && searchFields.usState) {
      cityState = searchFields.city + "%2C%20" + searchFields.usState;
    } else if (searchFields.city) {
      cityState = searchFields.city;
    } else if (searchFields.usState) {
      cityState = searchFields.usState;
    } else {
      cityState = "";
    }

    if (searchFields.search && searchFields.title) {
      diceSearch = searchFields.search + "%20" + searchFields.title;
    } else if (searchFields.title) {
      //diceTitle = "title%3A" + searchFields.title;
      diceTitle = searchFields.title;
    } else if (searchFields.search) {
      diceSearch = searchFields.search;
    } else {
      diceTitle = "";
    }

    if (!searchFields.company) {
      searchFields.company = "";
    }

    // Replaced the previous code.
    // let portalName   = "Dice";

    let searchString;

    searchString =
      "https://www.dice.com/jobs?q=" +
      diceTitle +
      diceSearch +
      "&location=" +
      cityState +
      "&filters.clientBrandNameFilter=" +
      searchFields.company +
      "&filters.employmentType=" +
      diceJobType +
      "&radius=" +
      outRadius +
      "&filters.postedDate=" +
      diceDatePosted;

    window.open(searchString, "_blank");
    portalName = "Dice";
    searchURL = searchString;
    addPortal();
  }

  function handleSearchGlassdoor() {
    console.log("clicked Glassdoor Search");

    // Set jobType for Glassdoor

    let glassdoorJobType = "";

    switch (searchFields.jobType) {
      case "Full Time":
        glassdoorJobType = "fulltime";
        break;
      case "Part Time":
        glassdoorJobType = "parttime";
        break;
      case "Contract":
        glassdoorJobType = "contract";
        break;
      case "Temporary":
        glassdoorJobType = "temporary";
        break;
      case "Internship":
        glassdoorJobType = "internship";
        break;
      default:
        glassdoorJobType = "";
    }

    console.log(searchFields.jobType);

    // Set expLevel for Glassdoor

    let glassdoorExpLevel = "";

    switch (searchFields.expLevel) {
      case "Internship":
        glassdoorJobType = "internship";
        break;
      case "Entry-Level":
        glassdoorExpLevel = "entrylevel";
        break;
      case "Mid-Level":
        glassdoorExpLevel = "midseniorlevel";
        break;
      case "Director":
        glassdoorExpLevel = "director";
        break;
      case "Executive":
        glassdoorExpLevel = "executive";
        break;
      default:
        glassdoorExpLevel = "";
    }

    console.log(searchFields.expLevel);

    // Set Radius for Glassdoor

    let outRadius = "";

    switch (searchFields.radius) {
      case "Exact Location":
        outRadius = "0";
        break;
      case "5 Mile Radius":
        outRadius = "5";
        break;
      case "10 Mile Radius":
        outRadius = "10";
        break;
      case "15 Mile Radius":
        outRadius = "15";
        break;
      case "20 Mile Radius":
      case "25 Mile Radius":
        outRadius = "25";
        break;
      case "30 Mile Radius":
        outRadius = "30";
      case "50 Mile Radius":
        outRadius = "50";
        break;
      default:
        outRadius = "";
        break;
    }

    console.log(outRadius);

    // Set Date Posted

    let outDatePosted = "";

    switch (searchFields.datePosted) {
      case "Last 24 hours":
        outDatePosted = "1";
        break;
      case "Last 3 days":
        outDatePosted = "3";
        break;
      case "Last 7 days":
        outDatePosted = "7";
        break;
      case "Last 14 days":
        outDatePosted = "14";
        break;
      case "Last 30 days":
        outDatePosted = "30";
        break;
      default:
        outDatePosted = "";
    }

    console.log(outDatePosted);

    let glassdoorSearch = "";
    let CompanyA = "";
    let CompanyB = "";
    let tempCompany = Number(searchFields.company);

    if (Number.isInteger(tempCompany)) {
      CompanyB = searchFields.company;
    } else {
      CompanyA = searchFields.company;
    }
    let tempSearch = "";

    // Concatenate title, search, company data into SEARCH field.

    if (searchFields.title && searchFields.search) {
      tempSearch = searchFields.title + "%20" + searchFields.search;
    } else if (searchFields.title) {
      tempSearch = searchFields.title;
    } else {
      tempSearch = searchFields.search;
    }

    if (tempSearch && CompanyA) {
      glassdoorSearch = tempSearch + "%20" + CompanyA;
    } else if (CompanyA) {
      glassdoorSearch = CompanyA;
    } else {
      glassdoorSearch = tempSearch;
    }

    let cityState = "&locT=N&locId=1";
    let tempState = searchFields.usState
      ? searchFields.usState.toString().toLowerCase()
      : cityState;
    console.log(tempState);

    switch (tempState) {
      case "al":
        cityState = "&locT=S&locId=105";
        break;
      case "ak":
        cityState = "&locT=S&locId=496";
        break;
      case "az":
        cityState = "&locT=S&locId=483";
        break;
      case "ar":
        cityState = "&locT=S&locId=1892";
        break;
      case "ca":
        cityState = "&locT=S&locId=2280";
        break;
      case "co":
        cityState = "&locT=S&locId=2519";
        break;
      case "ct":
        cityState = "&locT=S&locId=2697";
        break;
      case "de":
        cityState = "&locT=S&locId=3523";
        break;
      case "fl":
        cityState = "&locT=S&locId=3318";
        break;
      case "ga":
        cityState = "&locT=S&locId=3426";
        break;
      case "hi":
        cityState = "&locT=S&locId=1385";
        break;
      case "id":
        cityState = "&locT=S&locId=132";
        break;
      case "il":
        cityState = "&locT=S&locId=302";
        break;
      case "in":
        cityState = "&locT=S&locId=2124";
        break;
      case "ia":
        cityState = "&locT=S&locId=2733";
        break;
      case "ks":
        cityState = "&locT=S&locId=3107";
        break;
      case "ky":
        cityState = "&locT=S&locId=1141";
        break;
      case "la":
        cityState = "&locT=S&locId=2792";
        break;
      case "me":
        cityState = "&locT=S&locId=758";
        break;
      case "md":
        cityState = "&locT=S&locId=3201";
        break;
      case "ma":
        cityState = "&locT=S&locId=3399";
        break;
      case "mi":
        cityState = "&locT=S&locId=527";
        break;
      case "mn":
        cityState = "&locT=S&locId=1775";
        break;
      case "ms":
        cityState = "&locT=S&locId=1553";
        break;
      case "mo":
        cityState = "&locT=S&locId=386";
        break;
      case "mt":
        cityState = "&locT=S&locId=669";
        break;
      case "ne":
        cityState = "&locT=S&locId=792";
        break;
      case "nv":
        cityState = "&locT=S&locId=2756";
        break;
      case "nh":
        cityState = "&locT=S&locId=2403";
        break;
      case "nj":
        cityState = "&locT=S&locId=39";
        break;
      case "nm":
        cityState = "&locT=S&locId=1181";
        break;
      case "ny":
        cityState = "&locT=S&locId=428";
        break;
      case "nc":
        cityState = "&locT=S&locId=1282";
        break;
      case "nd":
        cityState = "&locT=S&locId=3517";
        break;
      case "oh":
        cityState = "&locT=S&locId=2235";
        break;
      case "ok":
        cityState = "&locT=S&locId=847";
        break;
      case "or":
        cityState = "&locT=S&locId=3163";
        break;
      case "pa":
        cityState = "&locT=S&locId=3185";
        break;
      case "ri":
        cityState = "&locT=S&locId=3156";
        break;
      case "sc":
        cityState = "&locT=S&locId=3411";
        break;
      case "sd":
        cityState = "&locT=S&locId=1502";
        break;
      case "tn":
        cityState = "&locT=S&locId=1968";
        break;
      case "tx":
        cityState = "&locT=S&locId=1347";
        break;
      case "ut":
        cityState = "&locT=S&locId=255";
        break;
      case "vt":
        cityState = "&locT=S&locId=1765";
        break;
      case "va":
        cityState = "&locT=S&locId=323";
        break;
      case "wa":
        cityState = "&locT=S&locId=3020";
        break;
      case "wv":
        cityState = "&locT=S&locId=1939";
        break;
      case "wi":
        cityState = "&locT=S&locId=481";
        break;
      case "wy":
        cityState = "&locT=S&locId=1258";
        break;
      default:
        cityState = "&locT=N&locId=1";
        break;
    }

    console.log(glassdoorSearch);

    let searchString;
    let httpsA = "https://www.glassdoor.com/Job/jobs.htm?suggestCount=0";
    let httpsB = "&suggestChosen=false&clickSource=searchBtn";

    // Clean up search string
    const searchStringValues = [
      httpsA,
      httpsB,
      cityState,
      CompanyB,
      glassdoorSearch,
      glassdoorJobType,
      glassdoorExpLevel,
      outDatePosted,
      outRadius,
    ];

    searchStringValues.forEach((value) => {
      handleNaN(value);
      handleUndefined(value);
    });

    searchString =
      httpsA +
      httpsB +
      cityState +
      "&companyId=" +
      CompanyB +
      "&typedKeyword=" +
      glassdoorSearch +
      "&sc.keyword=" +
      glassdoorSearch +
      "&jobType=" +
      glassdoorJobType +
      "&seniorityType=" +
      glassdoorExpLevel +
      "&fromAge=" +
      outDatePosted +
      "&radius=" +
      outRadius;

    window.open(searchString, "_blank");
    portalName = "Glassdoor";
    searchURL = searchString;
    addPortal();
  }

  function handleSearchIndeed() {
    console.log("clicked Indeed Search");

    // Set jobType for Indeed

    let indeedJobType = "";

    switch (searchFields.jobType) {
      case "Full Time":
        indeedJobType = "fulltime";
        break;
      case "Part Time":
        indeedJobType = "parttime";
        break;
      case "Contract":
        indeedJobType = "contract";
        break;
      case "Temporary":
        indeedJobType = "temporary";
        break;
      case "Internship":
        indeedJobType = "internship";
        break;
      case "Commission":
        indeedJobType = "commission";
        break;
      default:
        indeedJobType = "";
    }

    console.log(searchFields.jobType);

    // Set expLevel for Indeed

    let indeedExpLevel = "";

    switch (searchFields.expLevel) {
      case "Entry-Level":
        indeedExpLevel = "entry_level";
        break;
      case "Mid-Level":
        indeedExpLevel = "mid_level";
        break;
      case "Senior-Level":
        indeedExpLevel = "senior_level";
        break;
      default:
        indeedExpLevel = "";
    }

    console.log(searchFields.expLevel);

    // Set Radius for Indeed

    let outRadius = "";

    switch (searchFields.radius) {
      case "Exact Location":
        outRadius = "5";
        break;
      case "5 Mile Radius":
        outRadius = "5";
        break;
      case "10 Mile Radius":
        outRadius = "10";
        break;
      case "15 Mile Radius":
        outRadius = "15";
        break;
      case "25 Mile Radius":
        outRadius = "25";
        break;
      case "50 Mile Radius":
        outRadius = "50";
        break;
      default:
        outRadius = "";
    }

    console.log(searchFields.radius);

    // Set Date Posted

    let outDatePosted = "";

    switch (searchFields.datePosted) {
      case "Last 24 hours":
        outDatePosted = "1";
        break;
      case "Last 3 days":
        outDatePosted = "3";
        break;
      case "Last 7 days":
        outDatePosted = "7";
        break;
      case "Last 14 days":
        outDatePosted = "14";
        break;
      case "Last 30 days":
        outDatePosted = "Any";
        break;
      default:
        outDatePosted = "";
    }

    console.log(searchFields.datePosted);

    // Concatenate City and State

    let cityState = "";
    if (searchFields.city && searchFields.usState) {
      cityState = searchFields.city + "%2C%20" + searchFields.usState;
    } else {
      cityState = searchFields.city + searchFields.usState;
      //cityState = "";
    }

    // Replaced the previous code.
    // let portalName   = "Indeed";

    // Clean up search string
    const searchStringValues = [
      searchFields.search,
      cityState,
      searchFields.company,
      searchFields.title,
      indeedJobType,
      indeedExpLevel,
      outRadius,
      outDatePosted,
    ];

    searchStringValues.forEach((value) => {
      handleNaN(value);
      handleUndefined(value);
      //handleEmptySearch(value);
    });

    let searchString;

    // HAS NO SEARCH TERM

    if (searchFields.search === "" && searchFields.company) {
      console.log("Company Search for Indeed with no search term");
      searchString =
        "https://www.indeed.com/jobs?q=" +
        "company%3A%28" +
        searchFields.company +
        "%29" +
        "&l=" +
        cityState +
        "&jt=" +
        indeedJobType +
        "&explvl=" +
        indeedExpLevel +
        "&radius=" +
        outRadius +
        "&fromage=" +
        outDatePosted;
    }

    if (searchFields.search === "" && searchFields.title) {
      console.log("Company Search for Indeed with no search term");
      searchString =
        "https://www.indeed.com/jobs?q=" +
        "title%3A" +
        searchFields.title +
        "&l=" +
        cityState +
        "&jt=" +
        indeedJobType +
        "&explvl=" +
        indeedExpLevel +
        "&radius=" +
        outRadius +
        "&fromage=" +
        outDatePosted;
    }

    if (
      searchFields.search === "" &&
      searchFields.title &&
      searchFields.company
    ) {
      console.log("Company Search for Indeed with no search term");
      searchString =
        "https://www.indeed.com/jobs?q=" +
        "title%3A" +
        searchFields.title +
        "+company%3A%28" +
        searchFields.company +
        "%29" +
        "&l=" +
        cityState +
        "&jt=" +
        indeedJobType +
        "&explvl=" +
        indeedExpLevel +
        "&radius=" +
        outRadius +
        "&fromage=" +
        outDatePosted;
    }

    // HAS A SEARCH TERM
    if (searchFields.search && searchFields.company) {
      console.log("Company Search for Indeed that has a search term");
      searchString =
        "https://www.indeed.com/jobs?q=" +
        searchFields.search +
        "+company%3A%28" +
        searchFields.company +
        "%29" +
        "&l=" +
        cityState +
        "&jt=" +
        indeedJobType +
        "&explvl=" +
        indeedExpLevel +
        "&radius=" +
        outRadius +
        "&fromage=" +
        outDatePosted;
    }

    if (searchFields.search && searchFields.title) {
      console.log("Title Search for Indeed that has a search term");
      searchString =
        "https://www.indeed.com/jobs?q=" +
        searchFields.search +
        "%20title%3A" +
        searchFields.title +
        "&l=" +
        cityState +
        "&jt=" +
        indeedJobType +
        "&explvl=" +
        indeedExpLevel +
        "&radius=" +
        outRadius +
        "&fromage=" +
        outDatePosted;
    }

    if (searchFields.search && searchFields.title && searchFields.company) {
      console.log("Title and Company Search for Indeed that has a search term");
      searchString =
        "https://www.indeed.com/jobs?q=" +
        searchFields.search +
        "+title%3A" +
        searchFields.title +
        "+company%3A%28" +
        searchFields.company +
        "%29" +
        "&l=" +
        cityState +
        "&jt=" +
        indeedJobType +
        "&explvl=" +
        indeedExpLevel +
        "&radius=" +
        outRadius +
        "&fromage=" +
        outDatePosted;
    }

    if (searchFields.search && !searchFields.title && !searchFields.company) {
      console.log("Search for Indeed that has a search term");
      searchString =
        "https://www.indeed.com/jobs?q=" +
        searchFields.search +
        "&l=" +
        cityState +
        "&jt=" +
        indeedJobType +
        "&explvl=" +
        indeedExpLevel +
        "&radius=" +
        outRadius +
        "&fromage=" +
        outDatePosted;
    }

    console.log("Indeed Search String: " + searchString);
    window.open(searchString, "_blank");
    portalName = "Indeed";
    searchURL = searchString;
    addPortal();
    // Bandaid fix for the Indeed search
    // setSearchFields((prevstate) => ({
    //   ...prevstate,
    //   search: "",
    // }));
  }

  function handleSearchLinkedin() {
    console.log("clicked Linkedin Search");

    // Set jobType for Linkedin

    let linkedinJopType = "";

    switch (searchFields.jobType) {
      case "Full Time":
        linkedinJopType = "F";
        break;
      case "Part Time":
        linkedinJopType = "P";
        break;
      case "Contract":
        linkedinJopType = "C";
        break;
      case "Internship":
        linkedinJopType = "I";
        break;
      case "Temporary":
        linkedinJopType = "T";
        break;
      case "Volunteer":
        linkedinJopType = "V";
        break;
      case "Other":
        linkedinJopType = "O";
        break;
      default:
        linkedinJopType = "";
    }

    console.log(searchFields.jobType);

    // Set expLevel for Linkedin

    let linkedinExplevel = "";

    switch (searchFields.expLevel) {
      case "Internship":
        linkedinExplevel = "1";
        break;
      case "Entry-Level":
        linkedinExplevel = "2";
        break;
      case "Mid-Level":
        linkedinExplevel = "3";
        break;
      case "Senior-Level":
        linkedinExplevel = "4";
        break;
      case "Director":
        linkedinExplevel = "5";
        break;
      case "Executive":
        linkedinExplevel = "6";
        break;
      default:
        linkedinExplevel = "";
    }

    console.log(searchFields.expLevel);

    // Set Radius for Indeed

    let outRadius = "";

    switch (searchFields.radius) {
      case "Exact Location":
        outRadius = "10";
        break;
      case "5 Mile Radius":
      case "10 Mile Radius":
        outRadius = "10";
        break;
      case "15 Mile Radius":
      case "20 Mile Radius":
      case "25 Mile Radius":
        outRadius = "25";
        break;
      case "30 Mile Radius":
      case "50 Mile Radius":
        outRadius = "50";
        break;
      default:
        outRadius = "";
    }

    console.log(searchFields.radius);

    // Set Date Posted

    let outDatePosted = "";

    switch (searchFields.datePosted) {
      case "Last 24 hours":
        outDatePosted = "1";
        break;
      case "Last 3 days":
      case "Last 7 days":
        outDatePosted = "1%2C2";
        break;
      case "Last 14 days":
      case "Last 30 days":
        outDatePosted = "1%2C2%2C3%2C4";
        break;
      default:
        outDatePosted = "";
    }

    console.log(searchFields.datePosted);

    let linkedinState = "";
    let tempState = searchFields.usState
      ? searchFields.usState.toLowerCase()
      : linkedinState;

    switch (tempState.toString()) {
      case "al":
        linkedinState = "Alabama";
        break;
      case "ak":
        linkedinState = "Alaska";
        break;
      case "az":
        linkedinState = "Arizona";
        break;
      case "ar":
        linkedinState = "Arkansas";
        break;
      case "ca":
        linkedinState = "California";
        break;
      case "co":
        linkedinState = "Colorado";
        break;
      case "ct":
        linkedinState = "Connecticut";
        break;
      case "de":
        linkedinState = "Delaware";
        break;
      case "fl":
        linkedinState = "Florida";
        break;
      case "ga":
        linkedinState = "Georgia";
        break;
      case "hi":
        linkedinState = "Hawaii";
        break;
      case "id":
        linkedinState = "Idaho";
        break;
      case "il":
        linkedinState = "Illinois";
        break;
      case "in":
        linkedinState = "Indiana";
        break;
      case "ia":
        linkedinState = "Iowa";
        break;
      case "ks":
        linkedinState = "Kansas";
        break;
      case "ky":
        linkedinState = "Kentucky";
        break;
      case "la":
        linkedinState = "Louisiana";
        break;
      case "me":
        linkedinState = "Maine";
        break;
      case "md":
        linkedinState = "Maryland";
        break;
      case "ma":
        linkedinState = "Massachusetts";
        break;
      case "mi":
        linkedinState = "Michigan";
        break;
      case "mn":
        linkedinState = "Minnesota";
        break;
      case "ms":
        linkedinState = "Mississippi";
        break;
      case "mo":
        linkedinState = "Missouri";
        break;
      case "mt":
        linkedinState = "Montana";
        break;
      case "ne":
        linkedinState = "Nebraska";
        break;
      case "nv":
        linkedinState = "Nevada";
        break;
      case "nh":
        linkedinState = "New Hampshire";
        break;
      case "nj":
        linkedinState = "New Jersey";
        break;
      case "nm":
        linkedinState = "Mew Mexico";
        break;
      case "ny":
        linkedinState = "New York";
        break;
      case "nc":
        linkedinState = "North Carolina";
        break;
      case "nd":
        linkedinState = "North Dakota";
        break;
      case "oh":
        linkedinState = "Ohio";
        break;
      case "ok":
        linkedinState = "Oklahoma";
        break;
      case "or":
        linkedinState = "Oregon";
        break;
      case "pa":
        linkedinState = "Pennsylvania";
        break;
      case "ri":
        linkedinState = "Rhode Island";
        break;
      case "sc":
        linkedinState = "South Carolina";
        break;
      case "sd":
        linkedinState = "South Dakota";
        break;
      case "tn":
        linkedinState = "Tennessee";
        break;
      case "tx":
        linkedinState = "Texas";
        break;
      case "ut":
        linkedinState = "Utah";
        break;
      case "vt":
        linkedinState = "Vermont";
        break;
      case "va":
        linkedinState = "Virginia";
        break;
      case "wa":
        linkedinState = "Washington";
        break;
      case "wv":
        linkedinState = "West Virginia";
        break;
      case "wi":
        linkedinState = "Wisconsin";
        break;
      case "wy":
        linkedinState = "Wyoming";
        break;
      default:
        linkedinState = "";
        break;
    }

    // Concatenate State and USA

    let cityState = "United States";
    if (linkedinState) {
      cityState = linkedinState + "%2C%20United States";
    }

    let linkedinCompanyA = "";
    let linkedinCompanyB = "";
    let tempCompany = Number(searchFields.company);

    if (Number.isInteger(tempCompany)) {
      linkedinCompanyB = searchFields.company;
    } else {
      linkedinCompanyA = searchFields.company;
    }

    let tempSearch = "";
    let linkedinSearch = "";

    if (searchFields.title && searchFields.search) {
      tempSearch = searchFields.title + "%20" + searchFields.search;
    } else if (searchFields.title) {
      tempSearch = searchFields.title;
    } else {
      tempSearch = searchFields.search;
    }

    if (tempSearch && linkedinCompanyA) {
      linkedinSearch = tempSearch + "%20" + linkedinCompanyA;
    } else if (linkedinCompanyA) {
      linkedinSearch = linkedinCompanyA;
    } else {
      linkedinSearch = tempSearch;
    }

    console.log(linkedinSearch);

    // Clean up search string
    const searchStringValues = [
      linkedinSearch,
      cityState,
      linkedinCompanyB,
      linkedinJopType,
      linkedinExplevel,
      outDatePosted,
      outRadius,
    ];

    searchStringValues.forEach((value) => {
      handleNaN(value);
      handleUndefined(value);
    });

    let searchString;
    searchString =
      "https://www.linkedin.com/jobs/search?keywords=" +
      linkedinSearch +
      "&location=" +
      cityState +
      "&f_C=" +
      linkedinCompanyB +
      "&f_JT=" +
      linkedinJopType +
      "&f_E=" +
      linkedinExplevel +
      "&f_TP=" +
      outDatePosted +
      "&distance=" +
      outRadius;

    window.open(searchString, "_blank");
    portalName = "Linkedin";
    searchURL = searchString;
    addPortal();
  }

  function handleSearchLinkup() {
    console.log("clicked LinkUp Search");

    // Set Company

    let linkupCompany = searchFields.company;
    let companyName = "";
    if (searchFields.company) {
      companyName = linkupCompany.replace(/\s/g, "-");
      linkupCompany = "-at-" + companyName;
    }
    console.log(searchFields.company);

    // Set Radius

    let outRadius = "";

    switch (searchFields.radius) {
      case "Exact Location":
        outRadius = "0";
        break;
      case "5 Mile Radius":
      case "10 Mile Radius":
        outRadius = "10";
        break;
      case "15 Mile Radius":
        outRadius = "15";
        break;
      case "25 Mile Radius":
        outRadius = "25";
        break;
      case "30 Mile Radius":
      case "50 Mile Radius":
        outRadius = "50";
        break;
      default:
        outRadius = "";
    }
    console.log(searchFields.radius);

    // Set Date Posted

    let outDatePosted = "";

    switch (searchFields.datePosted) {
      case "Last 24 hours":
        outDatePosted = "1d";
        break;
      case "Last 3 days":
        outDatePosted = "";
        break;
      case "Last 7 days":
        outDatePosted = "7d";
        break;
      case "Last 14 days":
        outDatePosted = "14d";
        break;
      case "Last 30 days":
        outDatePosted = "30d";
        break;
      default:
        outDatePosted = "";
    }
    console.log(searchFields.datePosted);

    // Concatenate City and State

    let cityState = "";
    if (searchFields.city && searchFields.usState) {
      cityState = searchFields.city + "%2C%20" + searchFields.usState;
    } else {
      cityState = searchFields.city + searchFields.usState;
    }

    // Clean up search string
    const searchStringValues = [
      linkupCompany,
      cityState,
      searchFields.search,
      searchFields.title,
      outRadius,
      outDatePosted,
    ];

    searchStringValues.forEach((value) => {
      handleNaN(value);
      handleUndefined(value);
    });

    let searchString;
    searchString =
      "https://www.linkup.com/search/results/jobs" +
      linkupCompany +
      "?&location=" +
      cityState +
      "&keyword=" +
      searchFields.search +
      "&title_contains=" +
      searchFields.title +
      "&distance=" +
      outRadius +
      "&posted=" +
      outDatePosted;

    window.open(searchString, "_blank");
    portalName = "LinkUp";
    searchURL = searchString;
    addPortal();
  }

  function handleSearchMonster() {
    console.log("clicked Monster Search");

    // Set jobType for Monster

    let monsterJobType = "";

    switch (searchFields.jobType) {
      case "Full Time":
        monsterJobType = "Full-Time_8";
        break;
      case "Part Time":
        monsterJobType = "Part-Time_8";
        break;
      case "Contract":
        monsterJobType = "Contract_8";
        break;
      case "Temporary":
        monsterJobType = "Temp_8";
        break;
      case "Internship":
        monsterJobType = "Internship_8";
        break;
      case "Other":
        monsterJobType = "Other_8";
        break;
      default:
        monsterJobType = "";
    }
    console.log(searchFields.jobType);

    // Set Date Posted

    let outDatePosted = "";

    switch (searchFields.datePosted) {
      case "Last 24 hours":
        outDatePosted = "1";
        break;
      case "Last 3 days":
        outDatePosted = "3";
        break;
      case "Last 7 days":
        outDatePosted = "7";
        break;
      case "Last 14 days":
        outDatePosted = "14";
        break;
      case "Last 30 days":
        outDatePosted = "30";
        break;
      default:
        outDatePosted = "";
    }
    console.log(searchFields.datePosted);

    // Set Radius for Indeed

    let outRadius = "";

    switch (searchFields.radius) {
      case "Exact Location":
        outRadius = "0";
        break;
      case "10 Mile Radius":
        outRadius = "10";
        break;
      case "25 Mile Radius":
        outRadius = "25";
        break;
      case "50 Mile Radius":
        outRadius = "50";
        break;
      default:
        outRadius = "";
    }
    console.log(searchFields.radius);

    // Concatenate City and State

    let cityState = "";
    if (searchFields.city && searchFields.usState) {
      cityState = searchFields.city + "%2C%20" + searchFields.usState;
    } else {
      cityState = searchFields.city + searchFields.usState;
    }

    let monsterSearch = "";
    let monsterTitle = "";
    let moveTitle = "Yes";

    if (searchFields.title) {
      // monsterTitle = "title%3A" + searchFields.title;
      monsterTitle = searchFields.title;
    }

    if (searchFields.search) {
      monsterSearch = searchFields.search + "%20" + searchFields.title;
      moveTitle = "";
    } else {
      monsterSearch = monsterTitle;
    }

    if (searchFields.company && moveTitle) {
      monsterSearch = searchFields.title;
    }

    console.log(monsterSearch);

    // Clean up search string
    const searchStringValues = [
      monsterJobType,
      monsterSearch,
      cityState,
      searchFields.company,
      outRadius,
      outDatePosted,
    ];

    searchStringValues.forEach((value) => {
      handleNaN(value);
      handleUndefined(value);
    });

    let searchString;
    searchString =
      "https://www.monster.com/jobs/search/" +
      monsterJobType +
      "?q=" +
      monsterSearch +
      "&where=" +
      cityState +
      "&cn=" +
      searchFields.company +
      "&radius=" +
      outRadius +
      "&tm=" +
      outDatePosted;

    window.open(searchString, "_blank");
    portalName = "Monster";
    searchURL = searchString;
    addPortal();
  }

  function handleSearchSimplyHired() {
    console.log("clicked SimplyHired Search");

    // Set jobType for SimplyHired

    let shJobType = "";

    switch (searchFields.jobType) {
      case "Full Time":
        shJobType = "fulltime";
        break;
      case "Part Time":
        shJobType = "parttime";
        break;
      case "Contract":
        shJobType = "contract";
        break;
      case "Temporary":
        shJobType = "temporary";
        break;
      case "Internship":
        shJobType = "internship";
        break;
      case "Commission":
        shJobType = "commission";
        break;
      default:
        shJobType = "";
    }
    console.log(searchFields.jobType);

    // Set Radius

    let outRadius = "";

    switch (searchFields.radius) {
      case "Exact Location":
        outRadius = "0";
        break;
      case "5 Mile Radius":
        outRadius = "5";
        break;
      case "10 Mile Radius":
        outRadius = "10";
        break;
      case "15 Mile Radius":
        outRadius = "15";
        break;
      case "25 Mile Radius":
        outRadius = "25";
        break;
      case "50 Mile Radius":
        outRadius = "50";
        break;
      default:
        outRadius = "";
    }
    console.log(searchFields.radius);

    // Set Date Posted

    let outDatePosted = "";

    switch (searchFields.datePosted) {
      case "Last 24 hours":
        outDatePosted = "1";
        break;
      case "Last 3 days":
      case "Last 7 days":
        outDatePosted = "7";
        break;
      case "Last 14 days":
        outDatePosted = "14";
        break;
      case "Last 30 days":
        outDatePosted = "30";
        break;
      default:
        outDatePosted = "";
    }
    console.log(searchFields.datePosted);

    // Concatenate City and State

    let cityState = "";
    if (searchFields.city && searchFields.usState) {
      cityState = searchFields.city + "%2C%20" + searchFields.usState;
    } else {
      cityState = searchFields.city + searchFields.usState;
    }

    let shCompany;
    let shTitle;
    let shSearch = "";

    if (searchFields.company) {
      shCompany = "company%3A%28" + searchFields.company + "%29";
    } else {
      shCompany = "";
    }

    if (searchFields.title) {
      shTitle = "title%3A%28" + searchFields.title + "%29";
    } else {
      shTitle = "";
    }

    if (searchFields.search && searchFields.company) {
      shSearch = searchFields.search + "%20" + shCompany;
    } else {
      shSearch = searchFields.search + shCompany;
    }

    shCompany = "";
    if (shSearch && searchFields.title) {
      shCompany = shSearch + "%20" + shTitle;
    } else {
      shCompany = shSearch + shTitle;
    }

    // Clean up search string
    const searchStringValues = [shCompany, cityState, outRadius, outDatePosted];

    searchStringValues.forEach((value) => {
      handleNaN(value);
      handleUndefined(value);
    });

    let searchString;
    searchString =
      "https://www.simplyhired.com/search?q=" +
      shCompany +
      "&l=" +
      cityState +
      "&jt=" +
      shJobType +
      "&mi=" +
      outRadius +
      "&fdb=" +
      outDatePosted;

    window.open(searchString, "_blank");
    portalName = "SimplyHired";
    searchURL = searchString;
    addPortal();
  }

  function handleSearchZipRecruiter() {
    console.log("clicked ZipRecruiter Search");

    // Set jobType for Ziprecruiter

    let ziprecruiterJobType = "";
    let buffer = "";

    switch (searchFields.jobType) {
      case "Full Time":
        buffer = "full_time";
        break;
      case "Part Time":
        buffer = "part_time";
        break;
      case "Contract":
        buffer = "contract";
        break;
      case "Contract to Hire":
        buffer = "contract_to_hire";
        break;
      case "Temporary":
        buffer = "temporary";
        break;
      default:
        buffer = "";
    }

    if (buffer) {
      ziprecruiterJobType = "employment_type%3A" + buffer;
    }
    console.log(searchFields.jobType);

    // Set Date Posted

    let outDatePosted = "";

    switch (searchFields.datePosted) {
      case "Last 24 hours":
        outDatePosted = "1";
        break;
      case "Last 3 days":
        outDatePosted = "5";
        break;
      case "Last 7 days":
        outDatePosted = "5";
        break;
      case "Last 14 days":
        outDatePosted = "10";
        break;
      case "Last 30 days":
        outDatePosted = "30";
        break;
      default:
        outDatePosted = "";
    }
    console.log(searchFields.datePosted);

    // Set Radius for Indeed

    let outRadius = "";

    switch (searchFields.radius) {
      case "Exact Location":
        outRadius = "0";
        break;
      case "5 Mile Radius":
        outRadius = "5";
        break;
      case "10 Mile Radius":
        outRadius = "10";
        break;
      case "25 Mile Radius":
        outRadius = "25";
        break;
      case "50 Mile Radius":
        outRadius = "50";
        break;
      default:
        outRadius = "";
    }
    console.log(searchFields.radius);

    // Concatenate City and State

    let cityState = "";
    if (searchFields.city && searchFields.usState) {
      cityState = searchFields.city + "%2C%20" + searchFields.usState;
    } else {
      cityState = searchFields.city + searchFields.usState;
    }

    if (cityState.length === 0) {
      cityState = "United%20States";
    }

    // Clean up search string
    const searchStringValues = [
      searchFields.search,
      cityState,
      searchFields.company,
      searchFields.title,
      ziprecruiterJobType,
      outRadius,
      outDatePosted,
    ];

    searchStringValues.forEach((value) => {
      handleNaN(value);
      handleUndefined(value);
    });

    let searchString;
    searchString =
      "https://www.ziprecruiter.com/candidate/search?search=" +
      searchFields.search +
      "&location=" +
      cityState +
      "&refine_by_org_name=" +
      searchFields.company +
      "&refine_by_title=" +
      searchFields.title +
      "&refine_by_tags=" +
      ziprecruiterJobType +
      "&radius=" +
      outRadius +
      "&days=" +
      outDatePosted;

    window.open(searchString, "_blank");
    portalName = "Ziprecruiter";
    searchURL = searchString;
    addPortal();
  }

  //Render functions

  function renderJobSearchForm() {
    if (searchFields.search) {
      var clearSearchButton = (
        <Button
          variant="outline-secondary"
          onClick={(e) => setSearchFields({ ...searchFields, search: "" })}
        >
          X
        </Button>
      );
    }

    if (searchFields.city) {
      var clearCityButton = (
        <Button
          variant="outline-secondary"
          onClick={(e) => setSearchFields({ ...searchFields, city: "" })}
        >
          X
        </Button>
      );
    }

    if (searchFields.usState) {
      var clearStateButton = (
        <Button
          variant="outline-secondary"
          onClick={(e) => setSearchFields({ ...searchFields, usState: "" })}
        >
          X
        </Button>
      );
    }

    if (searchFields.company) {
      var clearCompanyButton = (
        <Button
          variant="outline-secondary"
          onClick={(e) => setSearchFields({ ...searchFields, company: "" })}
        >
          X
        </Button>
      );
    }

    if (searchFields.title) {
      var clearTitleButton = (
        <Button
          variant="outline-secondary"
          onClick={(e) => setSearchFields({ ...searchFields, title: "" })}
        >
          X
        </Button>
      );
    }

    if (searchFields.jobType) {
      var clearJobTypeButton = (
        <Button
          variant="outline-secondary"
          onClick={(e) => setSearchFields({ ...searchFields, jobType: "" })}
        >
          X
        </Button>
      );
    }

    if (searchFields.expLevel) {
      var clearExpLevelButton = (
        <Button
          variant="outline-secondary"
          onClick={(e) => setSearchFields({ ...searchFields, expLevel: "" })}
        >
          X
        </Button>
      );
    }

    if (searchFields.radius) {
      var clearRadiusButton = (
        <Button
          variant="outline-secondary"
          onClick={(e) => setSearchFields({ ...searchFields, radius: "" })}
        >
          X
        </Button>
      );
    }

    if (searchFields.datePosted) {
      var clearDatePostedButton = (
        <Button
          variant="outline-secondary"
          onClick={(e) => setSearchFields({ ...searchFields, datePosted: "" })}
        >
          X
        </Button>
      );
    }
    return (
      <CardGroup>
        <Card>
          <Card.Body>
            <Card.Title>
              <Button
                variant="standard"
                size="sm"
                active
                onClick={clearFields}
                className="float-right"
              >
                Clear
              </Button>
              <div className="section-header">
                <h2>1</h2> Enter Search Criteria
              </div>
            </Card.Title>
            <hr />
            <Card.Text>
              <Form>
                <InputGroup className="mb-3" controlId="search">
                  <Form.Label column sm="3">
                    Search
                  </Form.Label>
                  <Form.Control
                    placeholder=""
                    value={searchFields.search}
                    onChange={(e) =>
                      setSearchFields({
                        ...searchFields,
                        search: e.target.value,
                      })
                    }
                  />
                  <InputGroup.Append>{clearSearchButton}</InputGroup.Append>
                </InputGroup>
                <InputGroup className="mb-3" controlId="city">
                  <Form.Label column sm="3">
                    City
                  </Form.Label>
                  <Form.Control
                    placeholder=""
                    value={searchFields.city}
                    onChange={(e) =>
                      setSearchFields({ ...searchFields, city: e.target.value })
                    }
                  />
                  <InputGroup.Append>{clearCityButton}</InputGroup.Append>
                </InputGroup>
                <InputGroup className="mb-3" controlId="usState">
                  <Form.Label column sm="3">
                    State
                  </Form.Label>
                  <Form.Control
                    as="select"
                    value={searchFields.usState}
                    onChange={(e) =>
                      setSearchFields({
                        ...searchFields,
                        usState: e.target.value,
                      })
                    }
                  >
                    <option></option>
                    <option>United States</option>
                    <option>AK</option>
                    <option>AL</option>
                    <option>AR</option>
                    <option>AZ</option>
                    <option>CA</option>
                    <option>CO</option>
                    <option>CT</option>
                    <option>DE</option>
                    <option>FL</option>
                    <option>GA</option>
                    <option>HI</option>
                    <option>ID</option>
                    <option>IL</option>
                    <option>IN</option>
                    <option>IA</option>
                    <option>KS</option>
                    <option>KY</option>
                    <option>LA</option>
                    <option>ME</option>
                    <option>MD</option>
                    <option>MA</option>
                    <option>MI</option>
                    <option>MN</option>
                    <option>MS</option>
                    <option>MO</option>
                    <option>MT</option>
                    <option>NE</option>
                    <option>NV</option>
                    <option>NH</option>
                    <option>NJ</option>
                    <option>NM</option>
                    <option>NY</option>
                    <option>NC</option>
                    <option>ND</option>
                    <option>OH</option>
                    <option>OK</option>
                    <option>OR</option>
                    <option>PA</option>
                    <option>RI</option>
                    <option>SC</option>
                    <option>SD</option>
                    <option>TN</option>
                    <option>TX</option>
                    <option>UT</option>
                    <option>VT</option>
                    <option>VA</option>
                    <option>WA</option>
                    <option>WV</option>
                    <option>WI</option>
                    <option>WY</option>
                  </Form.Control>
                  <InputGroup.Append>{clearStateButton}</InputGroup.Append>
                </InputGroup>
                <InputGroup className="mb-3" controlId="company">
                  <Form.Label column sm="3">
                    Company
                  </Form.Label>
                  <Form.Control
                    placeholder=""
                    value={searchFields.company}
                    onChange={(e) =>
                      setSearchFields({
                        ...searchFields,
                        company: e.target.value,
                      })
                    }
                  />
                  <InputGroup.Append>{clearCompanyButton}</InputGroup.Append>
                </InputGroup>
                <InputGroup className="mb-3" controlId="title">
                  <Form.Label column sm="3">
                    Title
                  </Form.Label>
                  <Form.Control
                    placeholder=""
                    value={searchFields.title}
                    onChange={(e) =>
                      setSearchFields({
                        ...searchFields,
                        title: e.target.value,
                      })
                    }
                  />
                  <InputGroup.Append>{clearTitleButton}</InputGroup.Append>
                </InputGroup>
                <InputGroup className="mb-3" controlId="expLevel">
                  <Form.Label column sm="3">
                    ExpLevel
                  </Form.Label>
                  <Form.Control
                    as="select"
                    value={searchFields.expLevel}
                    onChange={(e) =>
                      setSearchFields({
                        ...searchFields,
                        expLevel: e.target.value,
                      })
                    }
                  >
                    <option></option>
                    <option>Internship</option>
                    <option>Entry-Level</option>
                    <option>Mid-Level</option>
                    <option>Senior-Level</option>
                    <option>Director</option>
                    <option>Executive</option>
                  </Form.Control>
                  <InputGroup.Append>{clearExpLevelButton}</InputGroup.Append>
                </InputGroup>
                <InputGroup className="mb-3" controlId="jobType">
                  <Form.Label column sm="3">
                    Type
                  </Form.Label>
                  <Form.Control
                    as="select"
                    value={searchFields.jobType}
                    onChange={(e) =>
                      setSearchFields({
                        ...searchFields,
                        jobType: e.target.value,
                      })
                    }
                  >
                    <option></option>
                    <option>Full Time</option>
                    <option>Part Time</option>
                    <option>Contract</option>
                    <option>Contract to Hire</option>
                    <option>Third Party</option>
                    <option>Temporary</option>
                    <option>Commission</option>
                    <option>Internship</option>
                    <option>Other</option>
                  </Form.Control>
                  <InputGroup.Append>{clearJobTypeButton}</InputGroup.Append>
                </InputGroup>
                <InputGroup className="mb-3" controlId="radius">
                  <Form.Label column sm="3">
                    Radius
                  </Form.Label>
                  <Form.Control
                    as="select"
                    value={searchFields.radius}
                    onChange={(e) =>
                      setSearchFields({
                        ...searchFields,
                        radius: e.target.value,
                      })
                    }
                  >
                    <option></option>
                    <option>Exact Location</option>
                    <option>5 Mile Radius</option>
                    <option>10 Mile Radius</option>
                    <option>15 Mile Radius</option>
                    <option>20 Mile Radius</option>
                    <option>25 Mile Radius</option>
                    <option>30 Mile Radius</option>
                    <option>50 Mile Radius</option>
                  </Form.Control>
                  <InputGroup.Append>{clearRadiusButton}</InputGroup.Append>
                </InputGroup>
                <InputGroup className="mb-3" controlId="datePosted">
                  <Form.Label column sm="3">
                    Posted
                  </Form.Label>
                  <Form.Control
                    as="select"
                    value={searchFields.datePosted}
                    onChange={(e) =>
                      setSearchFields({
                        ...searchFields,
                        datePosted: e.target.value,
                      })
                    }
                  >
                    <option></option>
                    <option>Last 24 hours</option>
                    <option>Last 3 days</option>
                    <option>Last 7 days</option>
                    <option>Last 14 days</option>
                    <option>Last 30 days</option>
                  </Form.Control>
                  <InputGroup.Append>{clearDatePostedButton}</InputGroup.Append>
                </InputGroup>
              </Form>
            </Card.Text>
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
            <Card.Title>
              <div className="section-header">
                <h2>2</h2> Click Portal
              </div>
            </Card.Title>
            <hr />
            <Card.Text>
              <ListGroup className="list-group-flush">
                {/* <ListGroupItem>
                <Button 
                  variant="link"
                  onClick={handleSearchMentor}
                >
                  Mentor
                </Button>
            </ListGroupItem> */}
                <ListGroupItem
                  onClick={handleSearchCareerBuilder}
                  action
                  variant="light"
                >
                  <h6 className="text-center portal-link">Career Builder</h6>
                </ListGroupItem>
                <ListGroupItem
                  onClick={handleSearchDice}
                  action
                  variant="light"
                >
                  <h6 className="text-center portal-link">Dice</h6>
                </ListGroupItem>
                <ListGroupItem
                  onClick={handleSearchGlassdoor}
                  action
                  variant="light"
                >
                  <h6 className="text-center portal-link">Glassdoor</h6>
                </ListGroupItem>
                <ListGroupItem
                  onClick={handleSearchIndeed}
                  action
                  variant="light"
                >
                  <h6 className="text-center portal-link">Indeed</h6>
                </ListGroupItem>
                <ListGroupItem
                  onClick={handleSearchLinkedin}
                  action
                  variant="light"
                >
                  <h6 className="text-center portal-link">Linkedin</h6>
                </ListGroupItem>
                <ListGroupItem
                  onClick={handleSearchLinkup}
                  action
                  variant="light"
                >
                  <h6 className="text-center portal-link">Linkup</h6>
                </ListGroupItem>
                <ListGroupItem
                  onClick={handleSearchMonster}
                  action
                  variant="light"
                >
                  <h6 className="text-center portal-link">Monster</h6>
                </ListGroupItem>
                <ListGroupItem
                  onClick={handleSearchSimplyHired}
                  action
                  variant="light"
                >
                  <h6 className="text-center portal-link">SimplyHired </h6>
                </ListGroupItem>
                <ListGroupItem
                  onClick={handleSearchZipRecruiter}
                  action
                  variant="light"
                >
                  <h6 className="text-center portal-link">ZipRecruiter </h6>
                </ListGroupItem>
              </ListGroup>
            </Card.Text>
          </Card.Body>
        </Card>
      </CardGroup>
    );
  }

  function renderLander() {
    return (
      <div className="Home">
        <div className="lander">
          <h2>Simplified Job Search</h2>
          <p className="text-muted">
            Search and bookmark your searches across multiple portals with a few
            clicks
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="Home">
      {isAuthenticated ? renderJobSearchForm() : renderLander()}
    </div>
  );
}
