// ------------------------------------------------------------------------------
// USER

const DEFAULT_USER_STATE = {
  errors: [],
  isAuthenticated: false,
  isRequired: false,
  userinfo: {},
  currentUserGroup: "",
  currentUserLocation: "",
  nonUSResidentsToSAccepted: false,
};

export const user = (state = DEFAULT_USER_STATE, action) => {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return {
        ...state,
        isAuthenticated: action.isAuthenticated,
      };
    case "CONFIRMED_USER":
      return {
        ...state,
        isRequired: action.isRequired,
      };
    case "CURRENT_USER_INFO":
      return {
        ...state,
        userinfo: action.userinfo,
      };
    case "CURRENT_USER_GROUP":
      return {
        ...state,
        currentUserGroup: action.currentUserGroup,
      };
    case "CURRENT_USER_LOCATION":
      return {
        ...state,
        currentUserLocation: action.currentUserLocation,
      };
    case "SET_NONUS_TOS_ACCEPT":
      return {
        ...state,
        nonUSResidentsToSAccepted: action.tosaccept,
      };
    case "SET_WALLET_ADDRESS":
      return {
        ...state,
        walletAddress: action.walletAddress,
      };
    case "LOGIN_ACTIVE":
      return {
        ...state,
        isAuthenticated: action.isAuthenticated,
      };
    case "LOGOUT_SUCCESS":
      return {
        ...state,
        isAuthenticated: action.isAuthenticated,
      };

    case "LOGIN_NOT_ACTIVE":
      return {
        ...state,
        isAuthenticated: action.isAuthenticated,
      };
    case "LOGIN_FAIL":
      return {
        ...state,
        isAuthenticated: action.isAuthenticated,
      };
    case "REQUIRES_CONFIRMATION":
      return {
        ...state,
        isRequired: action.isRequired,
      };
    case "ERROR":
      return {
        ...state,
        errors: [...state.errors, action.error],
      };
    case "MUTATE_ERRORS":
      return {
        ...state,
        errors: [...state.errors, action.errors],
      };
    case "CLEAR_ERRORS":
      return {
        ...state,
        errors: [],
      };
    default:
      return state;
  }
};

// ------------------------------------------------------------------------------
// JSEARCH

const DEFAULT_JSEARCH_STATE = {
  portals: [],
  searchFieldsGlobal: {},
};

export const jsearch = (state = DEFAULT_JSEARCH_STATE, action) => {
  switch (action.type) {
    case "MUTATE_PORTALS":
      return {
        ...state,
        portals: [...state.portals, action.portals],
      };
    case "MUTATE_SEARCH_FIELDS_GLOBAL":
      return {
        ...state,
        searchFieldsGlobal: action.searchFieldsGlobal,
      };
    default:
      return state;
  }
};
