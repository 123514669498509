import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Form, ProgressBar } from "react-bootstrap";
import { API } from "aws-amplify";
//import { API, Logger } from "aws-amplify";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import LoaderButton from "./LoaderButton";
import { useFormFields } from "../libs/hooksLib";
import { onError, logError } from "../libs/errorLib";
import "./BillingForm.css";
import config from "../config";
//Logger.LOG_LEVEL = 'DEBUG';

export default function BillingForm({
  isLoading,
  sponsorStripeAccountId,
  sponsorPaid,
  stripeAccountId,
  customerId,
  userprofileId,
  userFirstName,
  userLastName,
  userEmail,
  subscriptionCount,
  sponsorEmail,
  connectOnboarded,
  fullyOnboarded,
  authorizeCodeIsValid,
  authorizeCode,
  inviteCode,
  inviteCodeIsActive,
  userProfile,
}) {
  const stripe = useStripe();
  const elements = useElements();
  const [fields, handleFieldChange] = useFormFields({
    name: "",
  });
  const [isProcessing, setIsProcessing] = useState(false);
  const [isCardComplete, setIsCardComplete] = useState(false);
  const [progressState, setProgressState] = useState(0);
  const history = useHistory();

  isLoading = isProcessing || isLoading;
  useEffect(() => {
    async function onLoad() {
      // try {
      //   // Check if subscribed
      //   if (customerId) {
      //     const customer = await getCustomer(customerId);
      //     console.log(customer.customer.invoice_prefix);
      //     customer.customer.invoice_prefix
      //       ? setIsSubscribed(true)
      //       : setIsSubscribed(false);
      //   }
      // } catch (e) {
      //   logError(e);
      //   onError(e);
      // }
      if (config.isDemo) {
        console.log(
          "Sponsor Connected ID under billing: ",
          sponsorStripeAccountId
        ); // make sure to have the sponsor account before payment
        console.log("Sponsor Paid under billing.js: ", sponsorPaid); // make sure to check if sponsor paid
        console.log("Connect Onboarded under billing.js: ", connectOnboarded);
        console.log("Fully Onboarded under billing.js: ", fullyOnboarded);
      }
    }

    onLoad();
  }, [connectOnboarded, fullyOnboarded, sponsorStripeAccountId, sponsorPaid]);

  function validateForm() {
    return stripe && elements && fields.name !== "" && isCardComplete;
  }

  function createCustomer(name, email, paymentMethodId) {
    return API.post("userprofiles", "/marketplace/create-customer", {
      body: {
        name: name,
        email: email,
        paymentMethodId: paymentMethodId,
      },
    });
  }

  function createSubscriptionSchedule(
    stripeAccountId,
    customerId,
    sponsorStripeAccountId,
    paymentMethodId
  ) {
    return API.post(
      "userprofiles",
      "/marketplace/create-subscription-schedule",
      {
        body: {
          stripeAccountId: stripeAccountId,
          customerId: customerId,
          sponsorStripeAccountId: sponsorStripeAccountId,
          paymentMethodId: paymentMethodId,
        },
      }
    );
  }

  function saveProfile(profile) {
    if (config.isDemo) {
      console.log(
        "At-Billing.js-What is being saved to userprofile: ",
        profile
      );
    }
    return API.put("userprofiles", `/userprofiles/${userprofileId}`, {
      body: profile,
    });
  }

  // Activate Rewards
  async function handleSubmitClick(event) {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    setIsProcessing(true);
    if (isProcessing) {
      setProgressState(5);
    }

    // Get Payment Method ID to be passed on to the customer, subscription create objects
    // Using the stripeJS API, if standalone payments, confirmPayment, secret is leveraged
    // If failed, put the user back on the payment screen
    let paymentMethodId;

    try {
      const createPaymentMethodResult = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
        billing_details: {
          name: fields.name,
          email: userEmail,
        },
      });

      if (createPaymentMethodResult.paymentMethod) {
        paymentMethodId = createPaymentMethodResult.paymentMethod.id;
        //console.log(paymentMethodId);
      } else {
        console.log(
          "Looks like your CC may not be valid: ",
          createPaymentMethodResult.error
        );
        setIsProcessing(false);
        setProgressState(0);
      }
    } catch (e) {
      logError(e);
      console.log(
        "Creating Payment Method Failed with unknown Error: ",
        onError(e)
      );
    }

    if (paymentMethodId) {
      setProgressState(20);
    }

    // Set Customer Id if successful
    var stripeCustomerId = customerId ? customerId : "";
    if (config.isDemo) {
      console.log("CustomerId at profile check: ", stripeCustomerId);
    }

    // Create Customer of Customer ID (customerId) is not availble via user profile
    try {
      if (!customerId) {
        console.log(fields.name);
        const customer = await createCustomer(
          fields.name,
          userEmail,
          paymentMethodId
        );
        //console.log(customer.customer.id);
        stripeCustomerId = customer.customer.id;
      }
    } catch (e) {
      logError(e);
    }

    if (stripeCustomerId) {
      setProgressState(50);
    }

    fields.name = ""; // Empty the name field so that user not hit make payment button again by accident

    // Save Customer ID
    try {
      await saveProfile({
        ...userProfile,
        stripeAccountId,
        userFirstName,
        userLastName,
        userEmail,
        sponsorEmail,
        subscriptionCount,
        stripeCustomerId: stripeCustomerId,
      });
      setProgressState(80);
    } catch (e) {
      logError(e);
      onError(e);
    }

    // Create subscription schedule that is automated with no user ability to cancel or renew
    try {
      if (paymentMethodId && stripeCustomerId) {
        const subscriptionSchedule = await createSubscriptionSchedule(
          stripeAccountId,
          stripeCustomerId,
          sponsorPaid ? sponsorStripeAccountId : "", //Set Sponsor Connect ID only if Sponsor Paid, otherwise blank (goes to MindPool)
          paymentMethodId
        );

        setProgressState(60);

        if (subscriptionSchedule) {
          //console.log("Created Subscription Schedule: ", subscriptionSchedule);
          setProgressState(95);
          setTimeout(() => {
            setIsProcessing(false);
            history.push("/"); //To get global state via appContext(). Move to redux if it gets too complicated
          }, 1000);
        }
      }
    } catch (e) {
      logError(e);
      onError(e);
      history.push("/");
    }
  }

  function renderProgress() {
    return (
      <div>
        <ProgressBar
          now={progressState}
          label={`${progressState}%`}
          visuallyHidden
        />
      </div>
    );
  }

  function renderForm() {
    return (
      <div>
        <Form className="BillingForm" onSubmit={handleSubmitClick}>
          <Form.Group size="lg" controlId="name">
            <Form.Label>Cardholder&apos;s name</Form.Label>
            <Form.Control
              type="text"
              value={fields.name}
              onChange={handleFieldChange}
              placeholder="Name on the card"
              disabled={fullyOnboarded}
            />
          </Form.Group>
          <Form.Group size="lg" controlId="email">
            <Form.Label>Cardholder&apos;s email</Form.Label>
            <Form.Control
              type="email"
              value={userEmail}
              onChange={handleFieldChange}
              placeholder="Email"
              disabled
            />
          </Form.Group>
          <Form.Label>Credit Card Info</Form.Label>
          <CardElement
            className="card-field"
            disabled={fullyOnboarded}
            onChange={(e) => setIsCardComplete(e.complete)}
            options={{
              style: {
                base: {
                  fontSize: "16px",
                  color: "#495057",
                  fontFamily: "'Open Sans', sans-serif",
                },
              },
            }}
          />
          <LoaderButton
            block
            size="lg"
            type="submit"
            variant="msteams-sm"
            isLoading={isLoading}
            disabled={!validateForm()}
          >
            Activate
          </LoaderButton>
        </Form>
      </div>
    );
  }

  return (
    <div className="BillingForm">
      {isProcessing ? renderProgress() : <></>}
      {renderForm()}
    </div>
  );
}
