import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query-devtools";
import { Amplify } from "aws-amplify";
import "./index.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import App from "./App";
//import reportWebVitals from './reportWebVitals';
import config from "./config";

// Sentry
import { initSentry } from "./libs/errorLib";
initSentry();

// React Query Client
//const twentyFourHoursInMs = 1000 * 60 * 60 * 24;
//const fortyEightHoursInMs = twentyFourHoursInMs * 2;
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // refetchOnWindowFocus: false,
      // refetchOnMount: false,
      // refetchOnReconnect: true,
      retry: true,
      staleTime: 1000 * 20,
      // staleTime: 180000,
      // cacheTime: 300000,
      // refetchOnWindowFocus: false,
      // refetchOnmount: false,
      // refetchOnReconnect: false,
      // retry: false,
      // staleTime: twentyFourHoursInMs,
      // cacheTime: fortyEightHoursInMs,
    },
  },
});

// Google Analytics
import ReactGA from "react-ga";
process.env.REACT_APP_STAGE === "prod" &&
  ReactGA.initialize(config.GATrackingId, { debug: true });
process.env.REACT_APP_STAGE === "prod" &&
  ReactGA.pageview(window.location.pathname + window.location.search);

// GLOBAL CLIENT STATE with REDUX
import { Provider } from "react-redux";
import store from "./store/store";

// AMPLIFY
Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  },
  Storage: {
    region: config.s3.REGION,
    bucket: config.s3.BUCKET,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
  },
  API: {
    endpoints: [
      {
        name: "jobsearches",
        endpoint: config.apiGatewayJs.URL,
        region: config.apiGatewayJs.REGION,
      },
      {
        name: "userprofiles",
        endpoint: config.apiGatewayUp.URL,
        region: config.apiGatewayUp.REGION,
      },
      {
        name: "notes",
        endpoint: config.apiGatewayNs.URL,
        region: config.apiGatewayNs.REGION,
      },
    ],
  },
});

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <App />
          <ReactQueryDevtools initialIsOpen={false} />
        </Provider>
      </QueryClientProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
