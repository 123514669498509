/* eslint-disable */
import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect, useLocation } from "react-router-dom";

export default function AuthenticatedRoute({ children, ...rest }) {
  const { pathname, search } = useLocation();
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  return (
    <Route {...rest}>{isAuthenticated ? children : <Redirect to="/" />}</Route>
  );
}

/* 

<Redirect to={`/login?redirect=${pathname}${search}`} />
*/
