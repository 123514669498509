import React, { useRef, useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { API, Storage } from "aws-amplify";
import { onError } from "../../libs/errorLib";
import { s3Upload, s3Remove } from "../../libs/awsLib";
import { Link } from "react-router-dom";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import LoaderButton from "../../components/LoaderButton";
import config from "../../config";
import "./Notes.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export default function Notes() {
  const file = useRef(null);
  const { id } = useParams();
  const history = useHistory();
  const [note, setNote] = useState(null);
  const [title, setTitle] = useState("");
  const [tags, setTags] = useState("");
  const [link, setLink] = useState("");
  const [content, setContent] = useState("");
  const [attachment, setAttachment] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ align: [] }], // custom dropdown
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      [{ color: [] }, { background: [] }],
      ["link", "image"],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  useEffect(() => {
    function loadNote() {
      return API.get("notes", `/notes/${id}`);
    }

    async function onLoad() {
      try {
        const note = await loadNote();
        const { title, tags, link, content, attachment } = note;

        if (attachment) {
          note.attachmentURL = await Storage.vault.get(attachment);
        }
        setTitle(title);
        setTags(tags);
        setLink(link);
        setContent(content);
        setNote(note);
        setAttachment(note.attachment);
      } catch (e) {
        onError(e);
      }
    }

    onLoad();
  }, [id, attachment]);

  function validateForm() {
    return content.length > 0;
  }

  // function formatFilename(str) {
  //   return str.replace(/^\w+-/, "");
  // }

  // function handleFileChange(event) {
  //   if (attachment) {
  //     handleDeleteAttachment(attachment);
  //   }

  //   file.current = event.target.files[0];
  // }

  function handleDeleteAttachment(file) {
    s3Remove(file);
  }

  function saveNote(note) {
    return API.put("notes", `/notes/${id}`, {
      body: note,
    });
  }

  async function handleSubmit(event) {
    let attachment;

    event.preventDefault();

    if (file.current && file.current.size > config.MAX_ATTACHMENT_SIZE) {
      alert(
        `Please pick a file smaller than ${
          config.MAX_ATTACHMENT_SIZE / 1000000
        } MB.`
      );
      return;
    }

    setIsLoading(true);

    try {
      if (file.current) {
        attachment = await s3Upload(file.current);
      }

      await saveNote({
        title,
        tags,
        link,
        content,
        attachment: attachment || note.attachment,
      });
      history.push("/notes");
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }

  function deleteNote() {
    return API.del("notes", `/notes/${id}`);
  }

  async function handleDelete(event) {
    event.preventDefault();

    const confirmed = window.confirm(
      "Are you sure you want to delete this note?"
    );

    if (!confirmed) {
      return;
    }

    setIsDeleting(true);

    try {
      await deleteNote();
      handleDeleteAttachment(attachment);
      history.push("/notes");
    } catch (e) {
      onError(e);
      setIsDeleting(false);
    }
  }

  return (
    <div>
      {note ? (
        <div>
          <div class="header">
            <div class="info">
              <Link className="LoaderButton btn btn-warning" to="/notes">
                Back to Notes
              </Link>
            </div>
          </div>
          <div className="noteseditorg">
            <div className="container-fluid">
              <div className="Notes">
                <Card>
                  <Card.Body>
                    {note && (
                      <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="title">
                          <Form.Control
                            placeholder="Enter a title"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                          />
                        </Form.Group>
                        <Form.Group controlId="tags">
                          <Form.Control
                            placeholder="Add one or more tags (optional)"
                            value={tags}
                            onChange={(e) => setTags(e.target.value)}
                          />
                        </Form.Group>
                        <Form.Group controlId="link">
                          <Form.Control
                            placeholder="Add a related link or URL (optional)"
                            value={link}
                            onChange={(e) => setLink(e.target.value)}
                          />
                        </Form.Group>
                        {/* <Form.Group controlId="content">
                    <Form.Control
                      as="textarea"
                      placeholder="Add description"
                      value={content}
                      onChange={(e) => setContent(e.target.value)}
                    />
                  </Form.Group> */}
                        <Form.Group>
                          <ReactQuill
                            theme="snow"
                            value={content}
                            onChange={setContent}
                            modules={modules}
                            formats={formats}
                          />
                        </Form.Group>
                        {/* <Form.Group controlId="file">
                    <Form.Label>Attachment</Form.Label>
                    {note.attachment && (
                      <p>
                        
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={note.attachmentURL}
                        >
                          {formatFilename(note.attachment)}
                        </a>                                        
                      </p>
                    )}

                    <Form.Control onChange={handleFileChange} type="file" />
                  </Form.Group> */}
                        <LoaderButton
                          block
                          size="lg"
                          type="submit"
                          isLoading={isLoading}
                          disabled={!validateForm()}
                          className="msteams"
                        >
                          Save
                        </LoaderButton>
                        <LoaderButton
                          block
                          size="lg"
                          variant="danger"
                          onClick={handleDelete}
                          isLoading={isDeleting}
                          className="msteams"
                        >
                          Delete
                        </LoaderButton>
                      </Form>
                    )}
                  </Card.Body>
                </Card>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="container-fluid lander">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      )}
    </div>
  );
}
