import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { onError } from "../libs/errorLib";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import AppIcon from "../assets/images/app-icon3.svg";
import config from "../config";
import "./Home.css";

/* data loads */
//import { useQuery } from "react-query";
import { GetUserProfiles } from "../state/data/userProfiles";

export default function JSHome() {
  const [hasSponsor, setHasSponsor] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDemo, setIsDemo] = useState(false);
  const [connectOnboarded, setConnectOnboarded] = useState(false);
  const [fullyOnboarded, setFullyOnboarded] = useState(false);
  const [freeAccess, setFreeAccess] = useState(false);
  const [walletAddress, setWalletAddress] = useState("");
  const [nonUSResidentsToSAccepted, setNonUSResidentsToSAccepted] =
    useState(false);

  //Redux state
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  const userLocation = useSelector((state) => state.user.currentUserLocation);
  //const user = useSelector((state) => state.user.userinfo);

  // Data Fetch using a higher order component
  const {
    userProfiles,
    isFetchingUserProfiles,
    isLoadingUserProfiles,
    isSuccess,
    refetch,
  } = GetUserProfiles();
  config.isDemo &&
    console.log("Data from GetUserProfiles in Home-js: ", userProfiles);

  useEffect(() => {
    async function onLoad() {
      setIsLoading(true);
      if (config.isDemo) {
        setIsDemo(true);
      }
      if (!isAuthenticated) {
        return;
      }
      //console.log(JSON.stringify(process.env, undefined, 2));
      if (userProfiles && isSuccess) {
        try {
          //const userProfiles = await loadUserProfiles();
          //console.log(userProfiles);
          //setUserProfileId(userProfiles[0].userprofileId);
          //setUserEmail(userProfiles[0].userEmail);
          setConnectOnboarded(userProfiles[0].connectOnboarded);
          setFullyOnboarded(userProfiles[0].fullyOnboarded);
          setWalletAddress(userProfiles[0].walletAddress);
          setNonUSResidentsToSAccepted(
            userProfiles[0].nonUSResidentsToSAccepted
          );

          if (userProfiles[0].sponsorEmail) {
            if (
              userProfiles[0].sponsorEmail ===
              "user#" + userProfiles[0].userEmail
            ) {
              setHasSponsor(false);
            } else {
              setHasSponsor(true);
            }
          } else {
            setIsLoading(false);
            return;
          }

          if (
            userProfiles[0].paidUserCount > 0 ||
            userProfiles[0].fullyOnboarded
          ) {
            setFreeAccess(true);
          }
        } catch (e) {
          onError(e);
        }
      } else {
        refetch();
      }

      setIsLoading(false);
    }

    onLoad();
  }, [hasSponsor, isAuthenticated, userProfiles, refetch, isSuccess]);

  // function loadUserProfiles() {
  //   return API.get("userprofiles", "/userprofiles");
  // }

  function renderLander() {
    return (
      <main lass="lander-content" aria-label="Content" spellCheck="false">
        {isDemo ? (
          <Alert variant="danger">
            This is a demo environment. It provides a playground for anyone to
            learn and get familiar with our solutions. You will need to sign up
            for a separate account to access. Solutions are still in beta until
            future anouncements. All accounts are subject to removal without
            prior notice.{" "}
          </Alert>
        ) : (
          <></>
        )}
        {userLocation.country !== "US" ? (
          <Alert variant="danger">
            This application is available to US Residents only. If you are
            accessing from other regions, you will not be able to login or
            signup.{" "}
          </Alert>
        ) : (
          <></>
        )}
        <div className="">
          <div className="hero-container">
            <div className="hero" spellCheck="false">
              <div className="copy">
                <h2 className="">Optimized Mind</h2>
                <h3 className="">
                  Personal Meta Data Management Solutions simplify how you{" "}
                  <b className="">
                    store and find relevant information at lightning speed
                  </b>
                  . Check out our current suite of tools in beta -{" "}
                  <b className="">
                    bookmark job searches or store, search any information with
                    notes
                  </b>
                  .
                </h3>
                <div className="controls">
                  <div className="col1">
                    {userLocation.country === "US" && (
                      <Link className="signup" to="/signup">
                        Sign Up
                      </Link>
                    )}
                  </div>
                </div>
              </div>
              <div className="graphic-container">
                <div className="graphic">
                  <div className="wallpaper"></div>
                  <div className="browser">
                    <div className="bar">
                      {" "}
                      <span></span> <span></span> <span></span>{" "}
                    </div>
                    <img
                      src="https://istackspub.s3.amazonaws.com/mindpool/Notes.png"
                      className=""
                      alt=""
                    ></img>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="features">
            <hr className="hr-home" />
            <h4 className="opening">
              <b></b>
            </h4>
          </div>
          <div>
            <div className="">
              {" "}
              <span></span> <span></span> <span></span>{" "}
            </div>
            <div>
              <img
                src="https://istackspub.s3.amazonaws.com/mindpool/OptimizedMind-Solutions.png"
                className=""
                alt=""
                width=""
                height=""
              ></img>{" "}
            </div>
            <hr className="hr-home" />
            <img
              src="https://istackspub.s3.amazonaws.com/mindpool/OptimizedMind-Students.png"
              className=""
              alt=""
              width="500"
              height="500"
            ></img>{" "}
            <span></span> <span></span> <span></span>{" "}
            <img
              src="https://istackspub.s3.amazonaws.com/mindpool/OptimizedMind-Recruiters.png"
              className=""
              alt=""
              width="500"
              height="500"
            ></img>
            <hr className="hr-home" />
          </div>
        </div>
      </main>
    );
  }

  function solutionsAccess() {
    if (hasSponsor && fullyOnboarded) {
      return true;
      // TODO: add logic to check if user has free access (need to see if they have at least one invited user)
    } else if (hasSponsor && walletAddress) {
      return true;
    } else {
      return false;
    }
  }

  function renderSpinner() {
    return (
      isAuthenticated && (
        <div className="container-fluid lander">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      )
    );
  }

  function renderAppNavigation() {
    return (
      <div>
        <div className="org" spellCheck="false">
          <div className="header">
            <div className="info">
              <h4 className="tools-header">Solutions</h4>
            </div>
          </div>
          <ul className="">
            <li className="project">
              <div className="AppListPanel">
                <div className="icon">
                  <img alt="Notes" src={AppIcon} width="48" height="40"></img>
                </div>
                <div className="content">
                  <div className="col1">
                    <div className="section-header">
                      NOTES{" "}
                      <span className="app-header-subtitle">
                        [ Save Searchable Links{" "}
                        <span className="mid-stream-text-lowercase">with </span>{" "}
                        Tags{" "}
                        <span className="mid-stream-text-lowercase">
                          using the{" "}
                        </span>{" "}
                        Chrome Extension ]
                      </span>
                    </div>
                  </div>
                  <div className="col2">
                    {solutionsAccess() ? (
                      <div>
                        <a
                          className="LoaderButton btn btn-warning"
                          href="https://docs.mindpool.io/docs/notes/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Examples
                        </a>
                        <Link
                          className="LoaderButton btn btn-warning"
                          to="/notes"
                        >
                          Launch
                        </Link>
                      </div>
                    ) : (
                      <Link
                        className="LoaderButton btn btn-warning disabled"
                        to="/notes"
                      >
                        Launch
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </li>
            <li className="project">
              <div className="AppListPanel">
                <div className="icon">
                  <img
                    alt="JobSearch"
                    src={AppIcon}
                    width="48"
                    height="40"
                  ></img>
                </div>
                <div className="content">
                  <div className="col1">
                    <div className="section-header">SIMPLIFIED JOB SEARCH</div>
                  </div>
                  <div className="col2">
                    {solutionsAccess() ? (
                      <div>
                        <a
                          className="LoaderButton btn btn-warning"
                          href="https://docs.mindpool.io/docs/simplified-job-search/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Examples
                        </a>
                        <Link
                          className="LoaderButton btn btn-warning"
                          to="/jsearch"
                        >
                          Launch
                        </Link>
                      </div>
                    ) : (
                      <Link
                        className="LoaderButton btn btn-warning disabled"
                        to="/jsearch"
                      >
                        Launch
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </li>
            <li className="project">
              <div className="AppListPanel">
                <div className="icon">
                  <img alt="Notes" src={AppIcon} width="48" height="40"></img>
                </div>
                <div className="content">
                  <div className="col1">
                    <div className="section-header">
                      3-MONTH PROGRAM{" "}
                      <span className="app-header-subtitle">
                        [ Explore, Invite, Mentor,{" "}
                        <span className="mid-stream-text-lowercase">And </span>{" "}
                        Earn ]
                      </span>
                    </div>
                  </div>
                  <div className="col2">
                    {hasSponsor ? (
                      <div>
                        {freeAccess ? (
                          <Link
                            className="LoaderButton btn btn-warning"
                            to="/globalmarketplace"
                          >
                            Free Access
                          </Link>
                        ) : (
                          <Link
                            className="LoaderButton btn btn-warning disabled"
                            to="/globalmarketplace"
                          >
                            Free Access
                          </Link>
                        )}
                        <a
                          className="LoaderButton btn btn-warning"
                          href="https://mindpoolpub.s3.amazonaws.com/MindPool-3-Month-Program-CS-v1.pdf"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Workflow
                        </a>
                        <a
                          className="LoaderButton btn btn-warning"
                          href="https://mindpoolpub.s3.amazonaws.com/MindPool-FAQ.pdf"
                          target="_blank"
                          rel="noreferrer"
                        >
                          FAQ
                        </a>
                        <Link
                          className="LoaderButton btn btn-warning"
                          to="/marketplace"
                        >
                          Subscribe
                        </Link>
                      </div>
                    ) : (
                      <div>
                        <Link
                          className="LoaderButton btn btn-warning disabled"
                          to="/globalmarketplace"
                        >
                          Free Access
                        </Link>
                        <a
                          className="LoaderButton btn btn-warning"
                          href="https://mindpoolpub.s3.amazonaws.com/MindPool-3-Month-Program-CS-v1.pdf"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Workflow
                        </a>
                        <a
                          className="LoaderButton btn btn-warning"
                          href="https://mindpoolpub.s3.amazonaws.com/MindPool-FAQ.pdf"
                          target="_blank"
                          rel="noreferrer"
                        >
                          FAQ
                        </a>
                        <Link
                          className="LoaderButton btn btn-warning disabled"
                          to="/marketplace"
                        >
                          Subscribe
                        </Link>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </li>
            {/* <li className="project">
              <div className="AppListPanel">
                <div className="icon">
                  <img alt="Notes" src={AppIcon} width="48" height="40"></img>
                </div>
                <div className="content">
                  <div className="col1">
                    <div className="section-header">
                      3-MONTH PROGRAM{" "}
                      <span className="app-header-subtitle">
                        [ Explore, Invite, Mentor,{" "}
                        <span className="mid-stream-text-lowercase">And </span>{" "}
                        Earn ]
                      </span>
                    </div>
                  </div>
                  <div className="col2">
                    {hasSponsor ? (
                      <div>
                        {connectOnboarded || !freeAccess ? (
                          <Link
                            className="LoaderButton btn btn-warning disabled"
                            to="/globalmarketplace"
                          >
                            Free Access
                          </Link>
                        ) : (
                          <Link
                            className="LoaderButton btn btn-warning"
                            to="/globalmarketplace"
                          >
                            Free Access
                          </Link>
                        )}
                        <a
                          className="LoaderButton btn btn-warning"
                          href="https://mindpoolpub.s3.amazonaws.com/MindPool-3-Month-Program-CS-v1.pdf"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Workflow
                        </a>
                        <a
                          className="LoaderButton btn btn-warning"
                          href=" https://docs.mindpool.io/docs/3-month-program/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Value
                        </a>
                        {walletAddress || nonUSResidentsToSAccepted ? (
                          <Link
                            className="LoaderButton btn btn-warning disabled"
                            to="/marketplace"
                          >
                            Subscribe
                          </Link>
                        ) : (
                          <Link
                            className="LoaderButton btn btn-warning"
                            to="/marketplace"
                          >
                            Subscribe
                          </Link>
                        )}
                      </div>
                    ) : (
                      <div>
                        <Link
                          className="LoaderButton btn btn-warning disabled"
                          to="/globalmarketplace"
                        >
                          Free Access
                        </Link>
                        <a
                          className="LoaderButton btn btn-warning"
                          href="https://mindpoolpub.s3.amazonaws.com/MindPool-3-Month-Program-CS-v1.pdf"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Workflow
                        </a>
                        <a
                          className="LoaderButton btn btn-warning"
                          href=" https://docs.mindpool.io/docs/3-month-program/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Value
                        </a>
                        <Link
                          className="LoaderButton btn btn-warning disabled"
                          to="/marketplace"
                        >
                          Subscribe
                        </Link>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </li> */}
          </ul>
        </div>
        {renderAlert()}
      </div>
    );
  }

  function renderAlert() {
    return (
      <div>
        {!hasSponsor && !isLoading && !isLoadingUserProfiles ? (
          <Alert variant="warning">
            Thank you for signing up! Please enter a valid sponsor under
            Settings. Once entered, the sponsor cannot be modified.{" "}
          </Alert>
        ) : (
          <></>
        )}
        {hasSponsor &&
        !freeAccess &&
        !fullyOnboarded &&
        !isLoading &&
        !isLoadingUserProfiles ? (
          <Alert variant="warning">
            <ol>
              <li> Click on Subscribe to access MindPool Solutions.</li>
              <li>
                For Free Access, share the{" "}
                <a href="https://solutions.mindpool.io" target="_blank">
                  MindPool Solutions
                </a>{" "}
                link with others. The option will be available for you when one
                invited User subscribes.
              </li>
            </ol>
          </Alert>
        ) : (
          <></>
        )}
      </div>
    );
  }

  return (
    <div className="Home">
      {isAuthenticated && !isLoadingUserProfiles
        ? renderAppNavigation()
        : renderSpinner()}
      {!isAuthenticated && renderLander()}
    </div>
  );
}
