import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { checkLogin, setUserInfo, logout } from "./store/interactions";
import { useLocation } from "react-router-dom";
import { Auth, API } from "aws-amplify";
//import { AppContext } from "./libs/contextLib";
import { onError, logError } from "./libs/errorLib";
import Routes from "./Routes";
import ErrorBoundary from "./components/ErrorBoundary";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { LinkContainer } from "react-router-bootstrap";
import logo from "./assets/images/logo2.png";
import "./App.css";
import config from "./config";

/* data loads */
import { GetUserProfiles } from "./state/data/userProfiles";
//import { setUser } from "@sentry/react";

function App() {
  /* Global */

  const [isAuthenticating, setIsAuthenticating] = useState(true);

  //Redux state
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  const portals = useSelector((state) => state.jsearch.portals);
  const user = useSelector((state) => state.user.userinfo);
  const userLocation = useSelector((state) => state.user.currentUserLocation);

  /* Sponsor Related */

  const [sponsorStripeAccountId, setSponsorStripeAccountId] = useState("");
  const [sponsorPaid, setSponsorPaid] = useState(false);

  // Data Fetch using a higher order component
  const {
    userProfiles,
    refetch,
    isError,
    error,
    isSuccess,
    isLoadingUserProfiles,
    isFetchingUserProfiles,
  } = GetUserProfiles();
  config.isDemo &&
    console.log("Data from GetUserProfiles in App.js: ", userProfiles);

  useEffect(() => {
    function saveProfile(profile) {
      //console.log(userprofileId);
      return API.put(
        "userprofiles",
        `/userprofiles/${userProfiles[0].userprofileId}`,
        {
          body: profile,
        }
      );
    }
    async function onLoad() {
      try {
        const authCurrentSession = await Auth.currentSession();
        config.isDemo &&
          console.log("Auth.currentSession: ", authCurrentSession);
        if (authCurrentSession) {
          await checkLogin(dispatch);
        }
        if (isSuccess && !isError & !isLoadingUserProfiles) {
          const {
            sponsorEmail,
            stripeAccountId,
            connectOnboarded,
            subscriptionId,
            rewardsRenewalDate,
            stripeLoginLink,
          } = userProfiles[0];

          const today = Math.floor(new Date().getTime() / 1000);

          // Assigin and save portal link if it is only if [past 1 hour, charges_engabled is true, payouts_enable is true]
          // after connect onboarding
          if (
            !isFetchingUserProfiles &&
            !stripeLoginLink &&
            stripeAccountId &&
            connectOnboarded
          ) {
            try {
              // 1) Create Rewards portal link if connect and fully onboarded
              const loginLink = await createLoginLink(stripeAccountId);
              config.isDemo &&
                console.log(
                  "Gernerating and saving Rewards Portal loginLink in App.js: ",
                  loginLink
                );
              //setStripeLoginLink(loginLink.loginLink.url);
              await saveProfile({
                ...userProfiles[0],
                stripeLoginLink: loginLink.loginLink.url,
              });
              // Force this to set early to avoid not having a link
              setUserInfo(
                {
                  ...user,
                  stripeLoginLink: loginLink.loginLink.url,
                },
                dispatch
              );
            } catch (e) {
              logError(e);
              onError(e);
            }
          }

          // Update the correct rewards renewal date in the user profile

          if (today > rewardsRenewalDate) {
            if (config.isDemo) {
              console.log("Today is: ", today);
              console.log("Stored Renewal date is: ", rewardsRenewalDate);
            }

            if (subscriptionId) {
              // Get renewal date from subscription
              const subscription = await getSubscription(subscriptionId);
              if (config.isDemo) {
                console.log(
                  "Looks like we need to check if you have an active subscription: ",
                  subscription
                );
              }
              if (
                subscription.subscription.current_period_end >
                rewardsRenewalDate
              ) {
                const profileUpdate = await saveProfile({
                  ...userProfiles[0],
                  rewardsEnrolledDate: subscription.subscription.created,
                  rewardsRenewalDate:
                    subscription.subscription.current_period_end,
                });
                if (config.isDemo) {
                  console.log(
                    "Updating userprofile with correct renewal dates: ",
                    profileUpdate
                  );
                }
              }
            }
          }

          // Get paying sponsor and set the correct sponsor stripe account id
          // If unavailable, sponsorPaid is false, all amounts go to MindPool

          if (sponsorEmail) {
            // Get Sponsor's info from Sponsor's user profile. This is global and used for for both cases (see line 185)
            if (config.isDemo) {
              console.log("App-js-First-level Sponsor is: ", sponsorEmail); // debug
            }
            try {
              const payingSponsor = await getPayingSponsor(sponsorEmail);
              if (config.isDemo) {
                console.log("App.js-PAYING SPONSOR IS: ", payingSponsor); // debug
              }
              if (payingSponsor) {
                setSponsorStripeAccountId(payingSponsor.sponsorStripeAccountId);
                setSponsorPaid(true);
                // Force this to set early to avoid not having spponor paid and paying sponsor id
                setUserInfo(
                  {
                    ...user,
                    sponsorStripeAccountId:
                      payingSponsor.sponsorStripeAccountId,
                    sponsorPaid: true,
                  },
                  dispatch
                );
              } else {
                setSponsorStripeAccountId(""); // Payments go to MindPool account
                setSponsorPaid(false);
              }
            } catch (e) {
              onError(e);
              logError(e);
            }
          }

          if (!isFetchingUserProfiles && !isLoadingUserProfiles) {
            setUserInfo(
              {
                userEmail: userProfiles[0].userEmail,
                sponsorEmail: userProfiles[0].sponsorEmail,
                authorizeCode: userProfiles[0].authorizeCode,
                authorizeCodeIsValid: userProfiles[0].authorizeCodeIsValid,
                userFirstName: userProfiles[0].userFirstName,
                userLastName: userProfiles[0].userLastName,
                connectOnboarded: userProfiles[0].connectOnboarded,
                connectOnboardedDate: userProfiles[0].connectOnboardedDate,
                fullyOnboarded: userProfiles[0].fullyOnboarded,
                inviteCode: userProfiles[0].inviteCode,
                inviteCodeIsActive: userProfiles[0].inviteCodeIsActive,
                paidUserCount: userProfiles[0].paidUserCount,
                rewardsEnrolledDate: userProfiles[0].rewardsEnrolledDate,
                rewardsRenewalDate: userProfiles[0].rewardsRenewalDate,
                signedUpOn: userProfiles[0].signedUpOn,
                stripeAccountId: userProfiles[0].stripeAccountId,
                stripeCustomerId: userProfiles[0].stripeCustomerId,
                subscriptionId: userProfiles[0].subscriptionId,
                subscriptionCount: userProfiles[0].subscriptionCount,
                subscriptionTier: userProfiles[0].subscriptionTier,
                userprofileId: userProfiles[0].userprofileId,
                sponsorStripeAccountId: sponsorStripeAccountId,
                stripeLoginLink: stripeLoginLink,
                sponsorPaid: sponsorPaid,
                walletAddress: userProfiles[0].walletAddress,
                nonUSResidentsToSAccepted:
                  userProfiles[0].nonUSResidentsToSAccepted,
                //sponsorFieldDisabled: sponsorFieldDisabled,
              },
              dispatch
            );
          }
        } else {
          console.log("No user profiles found...refetching", error);
          refetch();
        }
      } catch (e) {
        if (e !== "No current user") {
          onError(e);
          logError(e);
        }
      }
      setIsAuthenticating(false);
    }
    onLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, userProfiles, setUserInfo, setIsAuthenticating]);

  function getSubscription(subscriptionId) {
    return API.post("userprofiles", "/marketplace/get-subscription", {
      body: {
        subscriptionId: subscriptionId,
      },
    });
  }

  function createLoginLink(accountId) {
    return API.post("userprofiles", "/marketplace/create-login-link", {
      body: {
        accountId: accountId,
      },
    });
  }

  function getPayingSponsor(sponsorEmail) {
    return API.post("userprofiles", "/sponsor/get-paying-sponsor", {
      body: {
        sponsorEmail: sponsorEmail,
      },
    });
  }

  // Logout
  const logoutHandler = async () => {
    await logout(dispatch);
  };

  function HeaderView() {
    const location = useLocation();
    //console.log(location.pathname);
    return location.pathname;
  }

  // function openAbout() {
  //   let url = "https://mindpoolpub.s3.amazonaws.com/MindPool-About-Final.pdf";
  //   window.open(url, "_blank");
  // }

  function openChromeExtDownload() {
    let url =
      "https://mindpoolpub.s3.amazonaws.com/softwaredist/MP-Notes-CRX/MP-Notes-CRX-Instructions.pdf";
    window.open(url, "_blank");
  }

  function openInDevelopment() {
    let url = "https://dev.mindpoollabs.com";
    window.open(url, "_blank");
  }

  return (
    !isAuthenticating && (
      <div className="App container py-3">
        <Navbar collapseOnSelect bg="light" expand="md" className="mb-3">
          <LinkContainer to="/">
            <Navbar.Brand className="font-weight-bold text-muted">
              <div>
                <img src={logo} width="160" height="70" className="" alt="" />
              </div>
            </Navbar.Brand>
          </LinkContainer>
          <Nav className="mr-auto">
            {isAuthenticated ? (
              <div>
                <LinkContainer to="">
                  <Nav.Link onClick={openInDevelopment} className="navlink">
                    Objective
                  </Nav.Link>
                </LinkContainer>
                <LinkContainer to="/">
                  <Nav.Link className="navlink">Home</Nav.Link>
                </LinkContainer>
                {/* <LinkContainer to="">
                  <Nav.Link onClick={openAbout} className="navlink">
                    About
                  </Nav.Link>
                </LinkContainer> */}
              </div>
            ) : (
              <></>
            )}
            {isAuthenticated && HeaderView() === "/notes" ? (
              <div>
                <LinkContainer to="/notes">
                  <Nav.Link className="navlink">Notes</Nav.Link>
                </LinkContainer>
              </div>
            ) : (
              <></>
            )}
            {isAuthenticated && HeaderView() === "/jsearch" ? (
              <div>
                <LinkContainer to="/jsearch">
                  <Nav.Link className="navlink">Job Search</Nav.Link>
                </LinkContainer>
              </div>
            ) : (
              <></>
            )}
            {isAuthenticated && HeaderView() === "/jsearch/history" ? (
              <div>
                <LinkContainer to="/jsearch">
                  <Nav.Link className="navlink">Job Search</Nav.Link>
                </LinkContainer>
              </div>
            ) : (
              <></>
            )}
            {isAuthenticated && HeaderView() === "/jsearch/bookmarks" ? (
              <div>
                <LinkContainer to="/jsearch">
                  <Nav.Link className="navlink">Job Search</Nav.Link>
                </LinkContainer>
              </div>
            ) : (
              <></>
            )}
          </Nav>
          <Navbar.Toggle />
          {HeaderView() === "/jsearch" ? (
            <Navbar.Collapse className="justify-content-end">
              <Nav activeKey={window.location.pathname}>
                {isAuthenticated && portals ? (
                  <>
                    {portals.length !== 0 ? (
                      <LinkContainer to="/jsearch/history">
                        <Nav.Link className="navlink">History</Nav.Link>
                      </LinkContainer>
                    ) : (
                      <div></div>
                    )}
                    <LinkContainer to="/jsearch/bookmarks">
                      <Nav.Link className="navlink">Bookmarks</Nav.Link>
                    </LinkContainer>
                    <LinkContainer to="/settings">
                      <Nav.Link className="navlink">Settings</Nav.Link>
                    </LinkContainer>
                    <Nav.Link onClick={logoutHandler} className="navlink">
                      Logout
                    </Nav.Link>
                  </>
                ) : (
                  <>
                    <LinkContainer to="/signup">
                      <Nav.Link className="navlink">Signup</Nav.Link>
                    </LinkContainer>
                    <LinkContainer to="/login">
                      <Nav.Link>Login</Nav.Link>
                    </LinkContainer>
                  </>
                )}
              </Nav>
            </Navbar.Collapse>
          ) : (
            <Navbar.Collapse className="justify-content-end">
              <Nav activeKey={window.location.pathname}>
                {isAuthenticated ? (
                  <>
                    {HeaderView() === "/" && (
                      <Nav.Link className="" onClick={openChromeExtDownload}>
                        Chrome Extension
                      </Nav.Link>
                    )}
                    {HeaderView() === "/notes" && (
                      <Nav.Link className="" onClick={openChromeExtDownload}>
                        Chrome Extension
                      </Nav.Link>
                    )}
                    {HeaderView() === "/jsearch/history" && (
                      <LinkContainer to="/jsearch/bookmarks">
                        <Nav.Link>Bookmarks</Nav.Link>
                      </LinkContainer>
                    )}
                    {HeaderView() === "/jsearch/bookmarks" &&
                    portals.length !== 0 ? (
                      <LinkContainer to="/jsearch/history">
                        <Nav.Link className="">History</Nav.Link>
                      </LinkContainer>
                    ) : (
                      <div></div>
                    )}
                    <LinkContainer to="/settings">
                      <Nav.Link>Settings</Nav.Link>
                    </LinkContainer>
                    <Nav.Link onClick={logoutHandler} className="logout">
                      Logout
                    </Nav.Link>
                  </>
                ) : (
                  <>
                    {userLocation.country === "US" && (
                      <>
                        <LinkContainer to="/signup">
                          <Nav.Link>Signup</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/login" className="login">
                          <Nav.Link>Login</Nav.Link>
                        </LinkContainer>
                      </>
                    )}
                  </>
                )}
              </Nav>
            </Navbar.Collapse>
          )}
        </Navbar>
        <ErrorBoundary>
          <Routes />
        </ErrorBoundary>
      </div>
    )
  );
}

export default App;
