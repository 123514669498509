import { useQuery } from "react-query";
import { Auth, API } from "aws-amplify";
import { onError, logError } from "../../libs/errorLib";

export const GetUserProfiles = () => {
  //const twentyFourHoursInMs = 1000 * 60 * 60 * 24;
  //const fortyEightHoursInMs = twentyFourHoursInMs * 2;
  const {
    data: userProfiles,
    isError,
    error,
    isSuccess,
    isLoading: isLoadingUserProfiles,
    isFetching: isFetchingUserProfiles,
    refetch,
  } = useQuery(
    ["userProfiles"],
    async () => {
      const authCurrentSession = await Auth.currentSession();
      if (authCurrentSession) {
        try {
          const userProfiles = await API.get("userprofiles", "/userprofiles");
          return userProfiles;
        } catch (e) {
          logError(e);
          onError(e);
        }
      }
    },
    {
      refetchOnWindowFocus: false,
      refetchOnmount: true,
      refetchOnReconnect: true,
      retry: true,
      // staleTime: twentyFourHoursInMs,
      // cacheTime: fortyEightHoursInMs,
    }
  );
  return {
    userProfiles,
    isError,
    error,
    isSuccess,
    isLoadingUserProfiles,
    isFetchingUserProfiles,
    refetch,
  };
};
