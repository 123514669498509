import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  setNonUSResidentToSAccept,
  setWalletGlobalState,
} from "../../store/interactions";
import { ethers } from "ethers";
//import { Link } from "react-router-dom";
//import { API, Logger } from "aws-amplify";
import { API } from "aws-amplify";
import {
  Row,
  Container,
  Card,
  Col,
  Spinner,
  Alert,
  Button,
  Form,
} from "react-bootstrap";
import config from "../../config";
import LoaderButton from "../../components/LoaderButton";
import "./GlobalMarketplace.css";
import { logError } from "../../libs/errorLib";

/* data loads */
import { GetUserProfiles } from "../../state/data/userProfiles";

//Logger.LOG_LEVEL = 'DEBUG'

export default function GlobalMarketplace() {
  //local state
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [enteredWalletAddress, setWalletAddress] = React.useState("");
  const [acceptedTerms, setAcceptedTerms] = React.useState(false);

  // Redux
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  // const user = useSelector((state) => state.user.userinfo);
  // config.isDemo && console.log("User via Redux in Globalmarketplace: ", user);

  // const userprofileId = user.userprofileId;
  // const walletAddress = user.walletAddress;
  // const nonUSResidentsToSAccepted = user.nonUSResidentsToSAccepted;

  // Data Fetch using a higher order component
  // const { userProfiles, isLoadingUserProfiles, refetch } = GetUserProfiles();
  const { userProfiles, isLoadingUserProfiles, refetch } = GetUserProfiles();
  config.isDemo &&
    console.log("Data from GetUserProfiles in Settings-js: ", userProfiles);

  // Set data from react-query
  const userprofileId = userProfiles ? userProfiles[0].userprofileId : "";
  const walletAddress = userProfiles ? userProfiles[0].walletAddress : "";
  const nonUSResidentsToSAccepted = userProfiles
    ? userProfiles[0].nonUSResidentsToSAccepted
    : false;

  useEffect(() => {
    async function onLoad() {
      if (!isAuthenticated) {
        return;
      }

      if (nonUSResidentsToSAccepted) {
        setAcceptedTerms(true);
      }
    }

    onLoad();
  }, [isAuthenticated, nonUSResidentsToSAccepted]);

  function saveProfile(profile) {
    console.log("Save Profile, what is being passed: ", profile);
    return API.put("userprofiles", `/userprofiles/${userprofileId}`, {
      body: profile,
    });
  }

  // async function handleAcceptToS() {
  //   try {
  //     await saveProfile({
  //       ...user,
  //       nonUSResidentsToSAccepted: true,
  //     });
  //     refetch();
  //   } catch (e) {
  //     logError(e);
  //   }
  // }

  function handleAcceptToS() {
    setAcceptedTerms(true);
  }

  function checkIsValidWalletAddress() {
    try {
      ethers.utils.getAddress(enteredWalletAddress);
      return true;
    } catch (e) {
      return false;
    }
  }

  function validateForm() {
    return checkIsValidWalletAddress() === true;
  }

  async function handleSaveWalletAddress(event) {
    event.preventDefault();
    setIsLoading(true);
    try {
      await saveProfile({
        ...userProfiles[0],
        walletAddress: enteredWalletAddress,
        nonUSResidentsToSAccepted: true,
      });
      setNonUSResidentToSAccept(true, dispatch);
      setWalletGlobalState(enteredWalletAddress, dispatch);
      setIsLoading(false);
      refetch();
      history.push("/");
    } catch (e) {
      logError(e);
    }
  }

  function openReferralDashboard() {
    let url;
    if (config.isDemo) {
      url = "https://referraldemo.mindpool.io";
    } else {
      url = "https://referral.mindpool.io";
    }
    window.open(url, "_blank");
  }

  function openOpportunity() {
    const url = "https://mpl.link/3EkepSo";
    window.open(url, "_blank");
  }

  function openEtherscan() {
    if (walletAddress) {
      const url = `https://etherscan.io/address/${walletAddress}`;
      window.open(url, "_blank");
    }
  }

  function renderForm() {
    return (
      <>
        {walletAddress && nonUSResidentsToSAccepted ? (
          <Alert variant="success">
            Congratulations! You have activated Free Access.{" "}
          </Alert>
        ) : (
          <></>
        )}
        <div className="org" spellCheck="false">
          <div className="header">
            <div className="info">
              <h4 className="marketplace-header">
                <span>
                  {" "}
                  3-MONTH PROGRAM{" "}
                  {/* <span className="marketplace-header-subtitle-lowercase">
                    {" "}
                    for{" "}
                  </span>
                  NON-US RESIDENTS{" "} */}
                  <span className="marketplace-header-subtitle">
                    [ Explore, Invite, Mentor,{" "}
                    <span className="mid-stream-text-lowercase">And </span>
                    Earn ]
                  </span>{" "}
                </span>
              </h4>
            </div>
          </div>
          <div className="AppListPanel-marketplace">
            <Container fluid>
              <Row>
                <Col>
                  <Card>
                    <Card.Body>
                      <Card.Title className="marketplace-header"></Card.Title>
                      <Card.Text>
                        {!walletAddress && !acceptedTerms ? (
                          <div>
                            <Alert variant="warning">
                              <ol>
                                <li>
                                  Free Access to Solutions requires at least one
                                  invited User who has signed up and entered you
                                  as the Sponsor.
                                </li>
                                <li>
                                  A Crypto Wallet (Ethereum) is required since
                                  we will soon transition to a DApp
                                  (Decentralized Application) for all Free
                                  Users.
                                </li>
                                <li>
                                  Click on Accept if you agree to the Terms of
                                  Service.
                                </li>
                                {/* <li>
                                  A Non-US Resident can take advantage of the
                                  3-Month Program to its fullest free of cost.
                                </li>
                                <li>
                                  Promotional offer. Expires on March 31, 2023.
                                </li>
                                <li>
                                  Create and share{" "}
                                  <a
                                    href="https://mpl.link/flyinst"
                                    target="_blank"
                                  >
                                    invite flyers
                                  </a>{" "}
                                  with US Residents who find MindPool solutions
                                  helpful.
                                </li>
                                <li>
                                  When an invited US Resident subscribes, the
                                  inviting Non-US Resident receives 15 USD.
                                </li>
                                <li>
                                  Payouts are made on the 10th of the following
                                  month for the previous month’s earnings.
                                </li>
                                <li>
                                  A minimum of 75 USD in accruals is required to
                                  disburse payouts. If not, payouts will roll
                                  into subsequent months.
                                </li>
                                <li>
                                  A Non-US Resident is required to provide an
                                  Ethereum wallet address to receive payouts.
                                </li>
                                <li>
                                  Payouts minus fees (gas fees) are made via
                                  Crypto - USDC (1 USD = 1 USDC stablecoin) to
                                  the wallet.
                                </li>
                                <li>
                                  MindPool is not responsible for transferring
                                  funds, if an incorrect wallet address is
                                  provided by the user.
                                </li>
                                <li>Click on Opportunity to learn more.</li>
                                <li>
                                  Click on Accept if you are a Non-US Resident
                                  and agree to the terms and conditions of the
                                  program.
                                </li> */}
                              </ol>
                            </Alert>
                            {/* <a
                              className="LoaderButton btn btn-warning"
                              onClick={openOpportunity}
                            >
                              Opportunity
                            </a> */}
                            <a
                              className="LoaderButton btn btn-warning"
                              onClick={handleAcceptToS}
                            >
                              Accept
                            </a>
                          </div>
                        ) : (
                          <></>
                        )}
                        {acceptedTerms && !walletAddress ? (
                          <div>
                            <Alert variant="warning">
                              <ol>
                                <li>
                                  We reccomend{" "}
                                  <a
                                    href="https://metamask.io/"
                                    target="_blank"
                                  >
                                    Metamask
                                  </a>
                                  , but use any wallet you are comfortable with
                                  that can send and receive Ethereum/ERC-20
                                  Tokens.
                                </li>
                                <li>
                                  Please follow recommended{" "}
                                  <a
                                    href="https://support.gilded.finance/en/articles/5952923-metamask-security-best-practices"
                                    target="_blank"
                                  >
                                    security practices{" "}
                                  </a>
                                  to avoid loss of funds.{" "}
                                </li>
                                <li>
                                  Double check and enter correct wallet address
                                  for payouts and click Save.
                                </li>
                                <li>
                                  Invalid wallet addresses will not work. We
                                  confirm with the Ethereum blockchain in the
                                  background before allowing you to Save.
                                </li>
                                <li>
                                  If required, you can change your wallet
                                  address later under Settings.
                                </li>
                              </ol>
                              {/* <a
                                className="LoaderButton btn btn-warning"
                                onClick={openOpportunity}
                              >
                                Opportunity
                              </a> */}
                            </Alert>
                            <Form onSubmit={handleSaveWalletAddress}>
                              <Form.Group controlId="userFirstName" size="lg">
                                <Form.Label>Crypto Wallet Address</Form.Label>
                                <Form.Control
                                  type=""
                                  value={enteredWalletAddress}
                                  onChange={(e) =>
                                    setWalletAddress(e.target.value)
                                  }
                                />
                              </Form.Group>
                              <LoaderButton
                                block
                                size="lg"
                                type="submit"
                                variant="msteams-sm"
                                isLoading={isLoading}
                                disabled={!validateForm()}
                              >
                                Save
                              </LoaderButton>
                            </Form>
                          </div>
                        ) : (
                          <></>
                        )}
                        {nonUSResidentsToSAccepted && walletAddress ? (
                          <>
                            <Alert variant="warning">
                              <ol>
                                {/* <li>
                                  Click on Opportunity to learn more about the
                                  program.
                                </li> */}
                                <li>
                                  Click on Dashboard to view statistics related
                                  to invited Users.
                                </li>
                                <li>
                                  Click on Transactions to view transactions of
                                  your wallet in the Blockchain.
                                </li>
                              </ol>
                            </Alert>
                            {/* <a
                              className="LoaderButton btn btn-warning"
                              onClick={openOpportunity}
                            >
                              Opportunity
                            </a> */}
                            <a
                              className="LoaderButton btn btn-warning"
                              onClick={openReferralDashboard}
                            >
                              Dashboard
                            </a>
                            <a
                              className="LoaderButton btn btn-warning"
                              onClick={openEtherscan}
                              disabled={!walletAddress}
                            >
                              Transactions
                            </a>
                          </>
                        ) : (
                          <></>
                        )}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </>
    );
  }

  function renderSpinner() {
    return (
      <div className="container-fluid lander">
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <div className="marketplace">
      {!isLoadingUserProfiles ? renderForm() : renderSpinner()}
    </div>
  );
}
