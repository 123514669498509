import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setPortals, setSearchFieldsGlobal } from "../../store/interactions";
import { API } from "aws-amplify";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";
import {
  Button,
  Tooltip,
  OverlayTrigger,
  Card,
  Alert,
  Spinner,
} from "react-bootstrap";
import { BsFillEyeFill, BsSearch, BsFillBookmarkFill } from "react-icons/bs";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import { onError } from "../../libs/errorLib";
import "./JSHistory.css";

export default function JSHistory() {
  //Redux state
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  const portals = useSelector((state) => state.jsearch.portals);
  const user = useSelector((state) => state.user.userinfo);
  const subscriptionCount = Number(user.subscriptionCount);

  const [bookmarks, setBookmarks] = useState([]);
  const [showBookmarkedAlert, setShowBookmarkedAlert] = useState(false);
  const { SearchBar } = Search;
  const history = useHistory();

  const renderSaveTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Bookmark this Search
    </Tooltip>
  );

  // const renderDisableSaveTooltip = (props) => (
  //   <Tooltip id="button-tooltip" {...props}>
  //     Plan bookmark limit reached
  //   </Tooltip>
  // );

  const renderViewTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      View Portal
    </Tooltip>
  );
  const renderSetTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Set Current Row as the Search Criteria
    </Tooltip>
  );

  const columns = [
    {
      dataField: "search",
      text: "Search Text",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "160px", textAlign: "center" };
      },
    },
    {
      dataField: "city",
      text: "City",
      hidden: true,
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "160px", textAlign: "center" };
      },
    },
    {
      dataField: "usState",
      text: "State",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "90px", textAlign: "center" };
      },
    },
    {
      dataField: "company",
      text: "Company",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "110px", textAlign: "center" };
      },
    },
    {
      dataField: "title",
      text: "Job Title",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "110px", textAlign: "center" };
      },
    },
    {
      dataField: "expLevel",
      text: "ExpL",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "100px", textAlign: "center" };
      },
    },
    {
      dataField: "jobType",
      text: "Type",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "90px", textAlign: "center" };
      },
    },
    {
      dataField: "radius",
      text: "Radius",
      hidden: true,
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "90px", textAlign: "center" };
      },
    },
    {
      dataField: "datePosted",
      text: "Posted",
      hidden: true,
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "90px", textAlign: "center" };
      },
    },
    {
      dataField: "searchDate",
      text: "Date",
      sort: true,
      order: "desc",
      headerStyle: (colum, colIndex) => {
        return { width: "100px", textAlign: "center" };
      },
      formatter: (cell, row, rowIndex) => {
        //console.log(dayjs(row.searchDate).format('YYYY/MM/DD-HH:mm:ss'));
        return (
          <div className="search-date">
            {dayjs(row.searchDate).format("YYYY/MM/DD HH:mm:ss")}
          </div>
        );
      },
    },
    {
      dataField: "portalName",
      text: "Portal",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "110px", textAlign: "center" };
      },
    },
    {
      text: "Action",
      isDummyField: true,
      headerStyle: (colum, colIndex) => {
        return { width: "140px", textAlign: "center" };
      },
      formatExtraData: bookmarks,
      subscriptionCount,
      portals,
      formatter: (cell, row, rowIndex) => {
        return (
          <div>
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 250, hide: 400 }}
              overlay={renderViewTooltip}
            >
              <Button
                id="link-button"
                variant="outline-secondary"
                size="sm"
                onClick={() => {
                  //console.log (`Product ${rowIndex} of Category ${row.Id} deleted`);
                  //console.log(row.searchURL);
                  window.open(row.searchURL, "_blank");
                }}
              >
                <BsFillEyeFill />
              </Button>
            </OverlayTrigger>
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 250, hide: 400 }}
              //overlay={ bookmarks.length < subscriptionCount || bookmarks.length === 0 ? renderSaveTooltip : renderDisableSaveTooltip }
              overlay={renderSaveTooltip}
            >
              <Button
                id="link-button"
                variant="outline-secondary"
                size="sm"
                onClick={async () => {
                  //console.log (`Product ${rowIndex} of Category ${row.Id} deleted`);
                  //console.log(new Date(row.searchDate));
                  //window.open (row.searchURL, '_blank');
                  //console.log(portals.filter( item => item.id === row.id));
                  //console.log(row.id);
                  //console.log(rowIndex);

                  if (bookmarks.length < subscriptionCount) {
                    try {
                      await bookmarkJobSearch({
                        searchText: row.search,
                        city: row.city,
                        usState: row.usState,
                        radius: row.radius,
                        company: row.company,
                        jobTitle: row.title,
                        jobType: row.jobType,
                        expLevel: row.expLevel,
                        datePosted: row.datePosted,
                        //searchDate: row.searchDate,
                        searchDate: Date.now(),
                        portalName: row.portalName,
                        portalURL: row.searchURL,
                      });

                      // drop object and set new portals array for History
                      setPortals(
                        portals.filter((portal) => portal.id !== row.id),
                        dispatch
                      );
                      history.push("/jsearch/bookmarks");
                      // setShowBookmarkedAlert(true);
                      // window.setTimeout(() => {
                      //   setShowBookmarkedAlert(false)
                      // }, 2000);
                    } catch (e) {
                      //console.log(e);
                      onError(e);
                    }
                  } else {
                    return;
                  }
                }}
              >
                <BsFillBookmarkFill />
              </Button>

              {/* 
              { (!nonSelectableRows.includes(rowIndex) && bookmarks.length < subscriptionCount ) ? 
                <Button
                    id="link-button" 
                    variant="outline-secondary"
                    size="sm"
                    onClick={async () => {
                        console.log("Row Index:", rowIndex);
                        console.log("Nonselectable Rows before:", nonSelectableRows);
                        try {
                          await bookmarkJobSearch({
                            searchText: row.search,
                            city: row.city,
                            usState: row.usState,
                            radius: row.radius,
                            company: row.company,
                            jobTitle: row.title,
                            jobType: row.jobType,
                            expLevel: row.expLevel,
                            datePosted: row.datePosted,
                            searchDate: row.searchDate,
                            portalName: row.portalName,
                            portalURL: row.searchURL
                          });
                          
                          setNonSelectableRows( nonSelectableRows => [...nonSelectableRows, rowIndex]);
                          // console.log("Nonselectable Rows after:", nonSelectableRows);
                          history.push("/");
                          history.push("/history")

                        } catch (e) {
                          //console.log(e);
                          onError(e);
                        }
                        console.log("Nonselectable Rows after:", nonSelectableRows);
                      } 
                    }
                    >
                    <BsFillBookmarkFill/>
                  </Button>
                :
                <Button
                    id="link-button" 
                    variant="outline-secondary"
                    disabled
                    size="sm"
                    onClick={async () => {
                        //console.log (`Product ${rowIndex} of Category ${row.Id} deleted`);
                        //console.log(new Date(row.searchDate));
                        //window.open (row.searchURL, '_blank');
                        //console.log(row);
                        //console.log(rowIndex);                     
                        try {

                          await bookmarkJobSearch({
                            searchText: row.search,
                            city: row.city,
                            usState: row.usState,
                            radius: row.radius,
                            company: row.company,
                            jobTitle: row.title,
                            jobType: row.jobType,
                            expLevel: row.expLevel,
                            datePosted: row.datePosted,
                            searchDate: row.searchDate,
                            portalName: row.portalName,
                            portalURL: row.searchURL
                          });
                          
                          setNonSelectableRows( nonSelectableRows => [...nonSelectableRows, rowIndex]);
                          

                        } catch (e) {
                          //console.log(e);
                          onError(e);
                        }                        
                      } 
                    }
                    >
                    <BsFillBookmarkFill/>
                  </Button>              

              }     */}
            </OverlayTrigger>
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 250, hide: 400 }}
              overlay={renderSetTooltip}
            >
              <Button
                id="link-button"
                variant="outline-secondary"
                size="sm"
                onClick={() => {
                  //console.log (`Product ${rowIndex} of Category ${row.Id} deleted`);
                  setSearchFieldsGlobal(
                    {
                      search: row.search,
                      city: row.city,
                      usState: row.usState,
                      radius: row.radius,
                      company: row.company,
                      title: row.title,
                      jobType: row.jobType,
                      expLevel: row.expLevel,
                      datePosted: row.datePosted,
                    },
                    dispatch
                  );
                  history.push("/jsearch");
                }}
              >
                <BsSearch />
              </Button>
            </OverlayTrigger>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    async function onLoad() {
      console.log("JSHistory-JS-Subscription Count:", subscriptionCount);
      if (!isAuthenticated && !subscriptionCount) {
        return;
      }

      try {
        const loadedBookmarks = await loadBookmarks();
        setBookmarks(loadedBookmarks);
        // const userProfiles = await loadUserProfiles();
        // const {
        //         subscriptionCount,
        //       } = userProfiles[0];

        // setSubscriptionCount(Number(subscriptionCount));
      } catch (e) {
        onError(e);
      }

      // if (loadBookmarks.length === subscriptionCount) {
      //   setIsExceeded(true);
      // }
    }

    onLoad();
    //console.log("Nonselectable Rows on Load", nonSelectableRows);
  }, [isAuthenticated, portals, subscriptionCount]);

  console.log("Bookmarks", bookmarks.length);
  console.log("Subscription Count", subscriptionCount);
  //console.log("Has Exceeded", isExceeded);

  // function loadUserProfiles() {
  //   return API.get("userprofiles", "/userprofiles");
  // }

  function loadBookmarks() {
    return API.get("jobsearches", "/jobsearches");
  }

  function bookmarkJobSearch(jobsearch) {
    return API.post("jobsearches", "/jobsearches", {
      body: jobsearch,
    });
  }

  function showDismissibleBookmarkedAlert() {
    if (showBookmarkedAlert) {
      return (
        <Alert
          variant="success"
          onClose={() => setShowBookmarkedAlert(false)}
          dismissible
        >
          <b>Bookmarked!</b> Click bookmarks to view and reuse search.
        </Alert>
      );
    }
    return (
      <Button onClick={() => setShowBookmarkedAlert(true)}>Show Alert</Button>
    );
  }

  // const expandRow = {
  //   renderer: (row, rowIndex) => (
  //     <div>
  //       <p>{ `This Expand row is belong to rowKey ${row.search}` }</p>
  //     </div>
  //   )
  // }

  // const selectRow = {
  //   mode: 'checkbox',
  //   clickToSelect: true,
  //   nonSelectable: nonSelectableRows,
  //   nonSelectableClasses: 'row-index-bigger-than-2101'
  // };

  //console.log("Portals in jshistory", portals);
  const portalsCopy = [...portals];
  const latestPortals = portalsCopy.sort((a, b) => {
    return b.searchDate - a.searchDate;
  });

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );
  const paginationOptions = {
    paginationSize: 10,
    pageStartIndex: 1,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "All",
        value: latestPortals.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  // Render main

  return (
    <div className="lander">
      {/* <BootstrapTable bootstrap4='true' 
          keyField='id' 
          data={ latestPortals } 
          columns={ columns }
          //expandRow={ expandRow }
        /> */}
      {bookmarks.length === subscriptionCount ? (
        <Alert variant="danger">
          You have reached the digital plan limit. No additional create is
          allowed.
        </Alert>
      ) : (
        <></>
      )}
      {showBookmarkedAlert ? showDismissibleBookmarkedAlert() : <></>}
      <ToolkitProvider
        keyField="id"
        bootstrap4="true"
        data={latestPortals}
        columns={columns}
        //selectRow={ selectRow }
        //hiddenRows={ nonSelectableRows }
        search
      >
        {(props) => (
          <div>
            <SearchBar
              {...props.searchProps}
              delay="1"
              className="search-bar"
              placeholder=" Enter a character (Search on any predefined input field)..."
            />
            <Card>
              <Card.Body>
                {bookmarks ? (
                  <BootstrapTable
                    pagination={paginationFactory(paginationOptions)}
                    {...props.baseProps}
                  />
                ) : (
                  <div className="container-fluid">
                    <Spinner animation="border" role="status">
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  </div>
                )}
              </Card.Body>
            </Card>
          </div>
        )}
      </ToolkitProvider>
    </div>
  );
}
