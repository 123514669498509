import React, { useRef, useState } from "react";
import { API } from "aws-amplify";
//import { API, Logger } from "aws-amplify";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import { useHistory, Link } from "react-router-dom";
import LoaderButton from "../../components/LoaderButton";
import { onError } from "../../libs/errorLib";
import { s3Upload } from "../../libs/awsLib";
import config from "../../config";
import "./NewNote.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

//Logger.LOG_LEVEL = 'DEBUG'

export default function NewNote() {
  const file = useRef(null);
  const history = useHistory();
  const [title, setTitle] = useState("");
  const [tags, setTags] = useState("");
  const [link, setLink] = useState("");
  const [content, setContent] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ align: [] }], // custom dropdown
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      [{ color: [] }, { background: [] }],
      ["link", "image"],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  function validateForm() {
    return content.length > 0;
  }

  // function handleFileChange(event) {
  //   file.current = event.target.files[0];
  // }

  async function handleSubmit(event) {
    event.preventDefault();

    if (file.current && file.current.size > config.MAX_ATTACHMENT_SIZE) {
      alert(
        `Please pick a file smaller than ${
          config.MAX_ATTACHMENT_SIZE / 1000000
        } MB.`
      );
      return;
    }

    setIsLoading(true);

    try {
      const attachment = file.current ? await s3Upload(file.current) : null;

      await createNote({ title, tags, link, content, attachment });
      history.push("/notes");
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }

  function createNote(note) {
    return API.post("notes", "/notes", {
      body: note,
    });
  }

  return (
    <div>
      <div>
        <div class="header">
          <div class="info">
            <Link className="LoaderButton btn btn-warning" to="/notes">
              Back to Notes
            </Link>
            <a
              className="LoaderButton btn btn-warning"
              href="https://secureservercdn.net/166.62.110.60/m5w.2c1.myftpupload.com/wp-content/uploads/2021/09/MP.Notes-Instructions.pdf?time=1633031881"
              target="_blank"
              rel="noreferrer"
            >
              Instructions
            </a>
          </div>
        </div>
        <div className="notesneworg container-fluid">
          <div className="NewNote">
            <Card>
              <Card.Body>
                <Form onSubmit={handleSubmit}>
                  <Form.Group controlId="title">
                    <Form.Control
                      placeholder="Enter a title"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group controlId="tags">
                    <Form.Control
                      placeholder="Add one or more tags (optional)"
                      value={tags}
                      onChange={(e) => setTags(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group controlId="link">
                    <Form.Control
                      placeholder="Add a related link or URL (optional)"
                      value={link}
                      onChange={(e) => setLink(e.target.value)}
                    />
                  </Form.Group>
                  {/* <Form.Group controlId="content">
                <Form.Control
                  placeholder="Add description"
                  value={content}
                  as="textarea"
                  onChange={(e) => setContent(e.target.value)}
                />
              </Form.Group> */}
                  <Form.Group>
                    <ReactQuill
                      theme="snow"
                      value={content}
                      onChange={setContent}
                      modules={modules}
                      formats={formats}
                    />
                  </Form.Group>
                  {/* <Form.Group controlId="file">
                <Form.Label>Attachment</Form.Label>
                <Form.Control onChange={handleFileChange} type="file" />
              </Form.Group> */}
                  <LoaderButton
                    block
                    type="submit"
                    size="lg"
                    variant="msteams"
                    isLoading={isLoading}
                    disabled={!validateForm()}
                  >
                    Create
                  </LoaderButton>
                </Form>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
}
