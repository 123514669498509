import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
//import { API, Logger } from "aws-amplify";
import { API } from "aws-amplify";
//import { useAppContext } from "../libs/contextLib";
import { Form, Card, Row, Spinner, Alert, Container } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { onError } from "../libs/errorLib";
import config from "../config";
import "./Settings.css";

//Logger.LOG_LEVEL = 'DEBUG'

/* data loads */
import { GetUserProfiles } from "../state/data/userProfiles";

export default function Settings() {
  const history = useHistory();
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);

  const [userProfile, setUserProfile] = useState({});

  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessfulSponsor, SetIsSuccessfulSponsor] = useState(false);
  const [userprofileId, setUserProfileId] = useState("");
  const [userFirstName, setUserFirstName] = useState("");
  const [userLastName, setUserLastName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [sponsorEmail, setSponsorEmail] = useState("");
  const [authorizeCode, setAuthorizeCode] = useState(""); // shared AuthroizeCode to be used with JMJ
  const [walletAddress, setWalletAddress] = useState("");

  // Redux
  const user = useSelector((state) => state.user.userinfo);
  config.isDemo && console.log("User Info via Redux: ", user);

  const [sponsorFieldDisabled, setSponsorFieldDisabled] = useState(false);
  const [walletAddressFieldDisabled, setWalletAddressFieldDisabled] =
    useState(false);
  const [authorizeCodeFieldDisabled, setAuthorizeCodeFieldDisabled] =
    useState(false);

  // Data Fetch using a higher order component
  const { userProfiles, refetch } = GetUserProfiles();
  config.isDemo &&
    console.log("Data from GetUserProfiles in Settings-js: ", userProfiles);

  useEffect(() => {
    async function onLoad() {
      setIsLoading(true);
      if (!isAuthenticated) {
        return;
      }

      //const userProfiles = await loadUserProfiles();
      if (userProfiles) {
        const {
          userprofileId,
          userFirstName,
          userLastName,
          userEmail,
          sponsorEmail,
          authorizeCode,
          walletAddress,
        } = userProfiles[0];
        setUserProfile(userProfiles[0]);
        setUserProfileId(userprofileId);
        setUserFirstName(userFirstName);
        setUserLastName(userLastName);
        setSponsorEmail(
          sponsorEmail === "user#" + userEmail ? "" : sponsorEmail
        );
        setAuthorizeCode(authorizeCode ? authorizeCode : ""); // shared AuthroizeCode to be used with JMJ
        setUserEmail(userEmail);
        setWalletAddress(walletAddress);

        const sponsorExists = await checkSponsorExists(sponsorEmail);
        if (sponsorExists) {
          setSponsorEmail(sponsorEmail);
          setSponsorFieldDisabled(true);
          //SetIsSuccessfulSponsor(true);
        }

        if (authorizeCode) {
          setAuthorizeCode(authorizeCode);
          setAuthorizeCodeFieldDisabled(true);
        }

        if (
          userProfiles[0].connectOnboarded ||
          !userProfiles[0].nonUSResidentsToSAccepted
        ) {
          setWalletAddressFieldDisabled(true);
        }
      }

      setIsLoading(false);
    }

    onLoad();
  }, [isAuthenticated, userProfiles]);

  function activateSolutions(profile) {
    //console.log(userprofileId);
    return API.put(
      "userprofiles",
      `/sponsor/activate-solutions/${userprofileId}`,
      {
        body: profile,
      }
    );
  }

  function checkIfAuthorizeCodeIsValid(inviteCode) {
    return API.post("userprofiles", "/sponsor/validate-authorizecode", {
      body: {
        inviteCode: inviteCode,
      },
    });
  }

  function checkSponsorExists(userEmail) {
    return API.post("userprofiles", "/sponsor/usersearch", {
      body: {
        userEmail: userEmail,
      },
    });
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    if (config.isDemo) {
      console.log("Sponsor Email in Settings", sponsorEmail);
    }
    switch (sponsorEmail) {
      case "jmj@mindpoollabs.net":
        try {
          const authorizeCodeIsValid = await checkIfAuthorizeCodeIsValid(
            authorizeCode.toLowerCase()
          );
          if (config.isDemo) {
            console.log("Authorize Code", authorizeCodeIsValid);
          }
          if (authorizeCodeIsValid) {
            setSponsorEmail(sponsorEmail);
            setAuthorizeCode(authorizeCode);
            setSponsorFieldDisabled(true);
            setAuthorizeCodeFieldDisabled(true);
            SetIsSuccessfulSponsor(true);
            await activateSolutions({
              ...userProfile,
              userFirstName: userFirstName,
              userLastName: userLastName,
              sponsorEmail: sponsorEmail,
              authorizeCode: authorizeCode ? authorizeCode.toLowerCase() : "",
              authorizeCodeIsValid: true,
              subscriptionTier: "digital",
              subscriptionCount: config.isDemo ? "20" : "50",
              walletAddress: walletAddress,
            });
          } else {
            setSponsorEmail("");
            setAuthorizeCode("");
            setSponsorFieldDisabled(false);
            setAuthorizeCodeFieldDisabled(false);
            SetIsSuccessfulSponsor(false);
            await activateSolutions({
              ...userProfile,
              userFirstName: userFirstName,
              userLastName: userLastName,
              sponsorEmail: "",
              authorizeCode: "",
              authorizeCodeIsValid: false,
              subscriptionTier: "free",
              subscriptionCount: "0",
              walletAddress: walletAddress,
            });
          }
          if (authorizeCodeIsValid) {
            setTimeout(() => {
              refetch();
              history.push("/");
            }, 1000);
          }
        } catch (e) {
          onError(e);
          setIsLoading(false);
        }
        break;
      default:
        try {
          const solutionActivation = await activateSolutions({
            ...userProfile,
            userFirstName: userFirstName,
            userLastName: userLastName,
            sponsorEmail: sponsorEmail,
            authorizeCode: "",
            authorizeCodeIsValid: false,
            subscriptionTier: "digital",
            subscriptionCount: config.isDemo ? "20" : "50",
            walletAddress: walletAddress,
          });

          console.log("Solution Activation", solutionActivation);

          if (solutionActivation) {
            setSponsorEmail(sponsorEmail);
            setSponsorFieldDisabled(true);
            SetIsSuccessfulSponsor(true);
            setTimeout(() => {
              refetch();
              history.push("/");
            }, 1000);
          } else {
            setSponsorEmail("");
            setSponsorFieldDisabled(false);
            SetIsSuccessfulSponsor(false);
          }
        } catch (e) {
          onError(e);
          setIsLoading(false);
        }
    }

    setIsLoading(false);
  }

  function renderForm() {
    return (
      <>
        {isSuccessfulSponsor ? (
          <Alert variant="success">
            Successfully entered valid data. Redirecting to Home.{" "}
          </Alert>
        ) : (
          <></>
        )}
        {!sponsorEmail ? (
          <Alert variant="warning">
            Enter name, a valid sponsor email, and click Save to activate
            solutions. Once entered, the sponsor email cannot be modified.
          </Alert>
        ) : (
          <></>
        )}
        <div className="org" spellCheck="false">
          <div className="header">
            <div className="info">
              <h4 className="settings-header"> Settings </h4>
            </div>
          </div>
          <div className="AppListPanel-settings">
            <Container fluid>
              <Row>
                <Card>
                  <Card.Body>
                    <Card.Title className="settings-header">Profile</Card.Title>
                    <br />
                    <Card.Text>
                      <div className="AppListPanel-settings">
                        <Form onSubmit={handleSubmit}>
                          <Form.Group controlId="userFirstName" size="lg">
                            <Form.Label>Your First Name</Form.Label>
                            <Form.Control
                              type=""
                              value={userFirstName}
                              onChange={(e) => setUserFirstName(e.target.value)}
                            />
                          </Form.Group>
                          <Form.Group controlId="userLastName" size="lg">
                            <Form.Label>Your Last Name</Form.Label>
                            <Form.Control
                              type=""
                              value={userLastName}
                              onChange={(e) => setUserLastName(e.target.value)}
                            />
                          </Form.Group>
                          <Form.Group controlId="sponsorEmail" size="lg">
                            <Form.Label>
                              Your Sponsor Email Who Invited You
                            </Form.Label>
                            <Form.Control
                              type="email"
                              value={sponsorEmail}
                              disabled={sponsorFieldDisabled}
                              onChange={(e) => {
                                setSponsorEmail(
                                  e.target.value !== userEmail
                                    ? e.target.value
                                    : ""
                                );
                              }}
                            />
                          </Form.Group>
                          <Form.Group controlId="authorizeCode" size="lg">
                            <Form.Label>
                              Authorized Code (if you have one)
                            </Form.Label>
                            <Form.Control
                              type=""
                              placeholder="Skip - Otherwise"
                              value={authorizeCode.toLowerCase()}
                              disabled={authorizeCodeFieldDisabled}
                              onChange={(e) => {
                                setAuthorizeCode(
                                  e.target.value !== authorizeCode
                                    ? e.target.value
                                    : ""
                                );
                              }}
                            />
                          </Form.Group>
                          <Form.Group controlId="userLastName" size="lg">
                            <Form.Label>Crypto Wallet Address</Form.Label>
                            <Form.Control
                              type=""
                              placeholder="After activating Free Access"
                              value={walletAddress}
                              onChange={(e) => setWalletAddress(e.target.value)}
                              disabled={walletAddressFieldDisabled}
                            />
                          </Form.Group>
                          <LoaderButton
                            block
                            size="lg"
                            type="submit"
                            variant="msteams-sm"
                            isLoading={isLoading}
                          >
                            Save
                          </LoaderButton>
                        </Form>
                      </div>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Row>
            </Container>
          </div>
        </div>
      </>
    );
  }

  function renderSpinner() {
    return (
      <div className="container-fluid lander">
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <div className="Settings">
      {!isLoading ? renderForm() : renderSpinner()}
    </div>
  );
}
