const dev = {
  s3: {
    REGION: "us-east-1",
    BUCKET: "yellowfin-upload",
  },
  apiGatewayJs: {
    REGION: "us-east-1",
    URL: "https://zu5zlizy91.execute-api.us-east-1.amazonaws.com/dev",
  },
  apiGatewayUp: {
    REGION: "us-east-1",
    URL: "https://mcqdsa2bu9.execute-api.us-east-1.amazonaws.com/dev",
  },
  apiGatewayNs: {
    REGION: "us-east-1",
    URL: "https://rejx519mdk.execute-api.us-east-1.amazonaws.com/dev",
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_A5Oxm1QxL",
    APP_CLIENT_ID: "25hibk4e7j8685jlmoiqvn7m9s",
    IDENTITY_POOL_ID: "us-east-1:7dabb5fb-ef8e-4d3c-96fa-b50da79900d0",
  },
  Tier: {
    plans: [
      {
        name: "digital",
        count: "45",
      },
      {
        name: "free",
        count: "0",
      },
    ],
  },
  STRIPE_KEY:
    "pk_test_51I1G1TBt16rD1QvRiVTI0odkBwu6fkwSvlcsibSzipyk1ZofHNSYVx9Ye9ubbO8QNmnryLahi3XW6tezMP1lrqxY00bdd2D1re",
  SENTRY: {
    dsn: "https://974564460d5f40e5b6c695d864b6e3c9@o1086632.ingest.sentry.io/6098966",
    tracesSampleRate: 1.0,
  },
  GATrackingId: "",
  isDemo: true,
};

const prod = {
  s3: {
    REGION: "us-east-1",
    BUCKET: "yellowfin-upload-prod",
  },
  apiGatewayJs: {
    REGION: "us-east-1",
    URL: "https://2ovo95o7b0.execute-api.us-east-1.amazonaws.com/prod",
  },
  apiGatewayUp: {
    REGION: "us-east-1",
    URL: "https://vvv0ztnd8b.execute-api.us-east-1.amazonaws.com/prod",
  },
  apiGatewayNs: {
    REGION: "us-east-1",
    URL: "https://fy7i0xa6za.execute-api.us-east-1.amazonaws.com/prod",
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_YOEqFWxj3",
    APP_CLIENT_ID: "4vpov4h1j9ttliv7p3uehkjem6",
    IDENTITY_POOL_ID: "us-east-1:2f4a8b0e-daea-4281-9b85-f3adb7e80e87",
  },
  Tier: {
    plans: [
      {
        name: "digital",
        count: "45",
      },
      {
        name: "free",
        count: "0",
      },
    ],
  },
  STRIPE_KEY:
    "pk_live_51I1G1TBt16rD1QvRAJX8Z0bd32WxhpQYU0eXPJAvHP0bHahbiPohwfZGXWBE3z8U8IZHqmTqp15vFHUQDitQpm9j00KobHKIXV",
  SENTRY: {
    dsn: "https://16ec8c24d6de4a629547385b26cab8a4@o1086632.ingest.sentry.io/6107249",
    tracesSampleRate: 1.0,
  },
  GATrackingId: "G-HBR9QDSW9G",
  isDemo: false,
};

const config = {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  // Default to dev if not set
  ...(process.env.REACT_APP_STAGE === "prod" ? prod : dev),
};

//console.log(config); // Make sure to disable this after debugging. Sensitive information for abuse.

export default config;
