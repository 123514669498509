import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { API } from "aws-amplify";
import dayjs from "dayjs";
import { useHistory, Link } from "react-router-dom";
import {
  Button,
  Tooltip,
  OverlayTrigger,
  Card,
  Spinner,
  Alert,
} from "react-bootstrap";
import { BsPencil, BsLink45Deg } from "react-icons/bs";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
//import { useAppContext } from "../../libs/contextLib";
import { onError } from "../../libs/errorLib";
import "./NSHome.css";

/* data loads */
import { useQuery } from "react-query";
//import { set } from "react-ga";

export default function NSHome() {
  const [notes, setNotes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { SearchBar } = Search;
  const history = useHistory();

  // Redux
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  const user = useSelector((state) => state.user.userinfo);
  const subscriptionCount = Number(user.subscriptionCount);

  const renderViewTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      View and Edit Note
    </Tooltip>
  );

  const renderLinkTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Click to Open Link
    </Tooltip>
  );

  const columns = [
    {
      dataField: "title",
      text: "Title",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "160px", textAlign: "center" };
      },
    },
    {
      dataField: "tags",
      text: "Tags",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "100px", textAlign: "center" };
      },
    },
    {
      dataField: "content",
      text: "Content",
      hidden: true,
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "160px", textAlign: "center" };
      },
    },
    {
      dataField: "createdAt",
      text: "Date",
      sort: true,
      order: "desc",
      headerStyle: (colum, colIndex) => {
        return { width: "50px", textAlign: "center" };
      },
      formatter: (cell, row, rowIndex) => {
        //console.log(dayjs(row.createdAt).format('YYYY/MM/DD-HH:mm:ss'));
        return (
          <div className="search-date">
            {dayjs(row.createdAt).format("YYYY/MM/DD HH:mm:ss")}
          </div>
        );
      },
    },
    {
      text: "Action",
      isDummyField: true,
      headerStyle: (colum, colIndex) => {
        return { width: "40px", textAlign: "center" };
      },
      formatter: (cell, row, rowIndex) => {
        return (
          <div>
            {row.link ? (
              <div>
                <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderLinkTooltip}
                >
                  <Button
                    id="link-button"
                    variant="outline-secondary"
                    size="sm"
                    onClick={() => {
                      //console.log (`Product ${rowIndex} of Category ${row.Id} deleted`);
                      //console.log(row);
                      window.open(row.link, "_blank");
                    }}
                  >
                    <BsLink45Deg />
                  </Button>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderViewTooltip}
                >
                  <Button
                    id="link-button"
                    variant="outline-secondary"
                    size="sm"
                    onClick={() => {
                      //console.log (`Product ${rowIndex} of Category ${row.Id} deleted`);
                      //console.log(row);
                      history.push(`/notes/${row.noteId}`);
                    }}
                  >
                    <BsPencil />
                  </Button>
                </OverlayTrigger>
              </div>
            ) : (
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={renderViewTooltip}
              >
                <Button
                  id="link-button"
                  variant="outline-secondary"
                  size="sm"
                  onClick={() => {
                    //console.log (`Product ${rowIndex} of Category ${row.Id} deleted`);
                    //console.log(row);
                    history.push(`/notes/${row.noteId}`);
                  }}
                >
                  <BsPencil />
                </Button>
              </OverlayTrigger>
            )}
          </div>
        );
      },
    },
  ];

  // Data Fetch
  const {
    data: loadedNotes = [],
    isLoading: isLoadingNotes,
    isError,
    error,
    isSuccess,
    refetch,
  } = useQuery(
    ["notes"],
    async () => {
      setIsLoading(true);
      try {
        const notes = await API.get("notes", "/notes");
        setIsLoading(false);
        return notes;
      } catch (error) {
        onError(error);
        return [];
      }
    },
    {
      refetchOnWindowFocus: true,
      retry: true,
      //refetchOnMount: true,
    }
  );

  useEffect(() => {
    async function onLoad() {
      if (!isAuthenticated) {
        return;
      }

      if (isSuccess) {
        setNotes(loadedNotes);
        setIsLoading(false);
      }

      setIsLoading(false);
    }

    onLoad();
    //console.log(notes);
  }, [loadedNotes, isSuccess, isAuthenticated]);

  // function loadNotes() {
  //   return API.get("notes", "/notes");
  // }

  //console.log(bookmarks);

  // const expandRow = {
  //   renderer: (row, rowIndex) => (
  //     <div>
  //       <p>{ `This Expand row is belong to rowKey ${row.search}` }</p>
  //     </div>
  //   )
  // }

  // const selectRow = {
  //   mode: 'checkbox',
  //   clickToSelect: true,
  //   nonSelectable: nonSelectableRows,
  //   nonSelectableClasses: 'row-index-bigger-than-2101'
  // };

  const latestNotes = notes.sort((a, b) => {
    return b.createdAt - a.createdAt;
  });

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );
  const paginationOptions = {
    paginationSize: 10,
    pageStartIndex: 1,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "All",
        value: latestNotes.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  function renderNotes() {
    return (
      <div>
        {/* <BootstrapTable bootstrap4='true' 
            keyField='id' 
            data={ latestPortals } 
            columns={ columns }
            //expandRow={ expandRow }
          /> */}
        {isSuccess && loadedNotes.length !== 0 ? (
          <ToolkitProvider
            keyField="id"
            bootstrap4="true"
            data={latestNotes}
            columns={columns}
            //selectRow={ selectRow }
            //hiddenRows={ nonSelectableRows }
            search
          >
            {(props) => (
              <div>
                <div class="header">
                  <div class="info">
                    {loadedNotes.length === subscriptionCount ? (
                      <div>
                        <Alert className="notesalert" variant="danger">
                          You have reached the digital plan limit. No additional
                          create is allowed.
                        </Alert>
                      </div>
                    ) : (
                      <div>
                        <Link
                          className="LoaderButton btn btn-warning"
                          to="/notes/new"
                        >
                          + Create a Note
                        </Link>
                        <Link
                          className="LoaderButton btn btn-warning"
                          onClick={(e) => {
                            e.preventDefault();
                            window.open(
                              "https://docs.mindpool.io/docs/notes/",
                              "_target"
                            );
                          }}
                        >
                          Learn More
                        </Link>
                      </div>
                    )}
                  </div>
                </div>
                <div className="container-fluid notesorg">
                  <SearchBar
                    {...props.searchProps}
                    delay="1"
                    className="search-bar"
                    placeholder="Enter one or more characters (Search on a predefined: Title, Tags, Description, Date)"
                  />
                  <Card className="container-fluid">
                    <Card.Body>
                      <BootstrapTable
                        pagination={paginationFactory(paginationOptions)}
                        {...props.baseProps}
                      />
                    </Card.Body>
                  </Card>
                </div>
              </div>
            )}
          </ToolkitProvider>
        ) : (
          <div className="lander">
            <div className="container">
              <div className="Home">
                <div className="lander">
                  <p className="text-muted">Currently there are no notes.</p>
                  <Link
                    className="LoaderButton btn btn-warning align-content-center"
                    to="/notes/new"
                  >
                    + Create a Note
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  function renderSpinner() {
    return (
      <div className="container-fluid lander">
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    );
  }

  return <div>{!isLoadingNotes ? renderNotes() : renderSpinner()}</div>;
}
