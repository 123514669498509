// import { ethers } from 'ethers';
// import TOKEN_ABI from '../abis/Token.json';
// import EXCHANGE_ABI from '../abis/Exchange.json';

// WEB 2.0 RELATED IMPORTS
import { Auth } from "aws-amplify";
import { onError } from "../libs/errorLib";
import genUUID from "../utils/genUUID";

/* USER */
export const login = async (email, password, dispatch) => {
  try {
    await Auth.signIn(email, password);
    if (Auth.user) {
      dispatch({ type: "LOGIN_SUCCESS", isAuthenticated: true });
    }
  } catch (e) {
    console.log("Error logging in", e);
    if (e.code === "UserNotConfirmedException") {
      Auth.resendSignUp(email);
      dispatch({ type: "REQUIRES_CONFIRMATION", isRequired: true });
    } else {
      dispatch({ type: "CONFIRMED_USER", isRequired: false });
    }
    dispatch({ type: "LOGIN_FAIL", isAuthenticated: false });
    dispatch({
      type: "ERROR",
      error: {
        id: genUUID(),
        text: onError(e),
      },
    });
  }
};

export const checkLogin = async (dispatch) => {
  try {
    const currentSession = await Auth.currentSession();
    //console.log(Auth);
    if (currentSession) {
      dispatch({ type: "LOGIN_ACTIVE", isAuthenticated: true });
    }
  } catch (e) {
    //onError(e);
    dispatch({ type: "LOGIN_NOT_ACTIVE", isAuthenticated: false });
  }
};

// ------------------------------------------------------------------------------
// LOGOUT

export const logout = async (dispatch) => {
  try {
    await Auth.signOut();
    //console.log(Auth);
    dispatch({ type: "LOGOUT_SUCCESS", isAuthenticated: false });
    dispatch({ type: "CURRENT_USER_GROUP" });
    dispatch({ type: "CLEAR_ERRORS" });
  } catch (e) {
    onError(e);
  }
};

// ------------------------------------------------------------------------------
// CURRENT USER

export const setCurrentUserGroup = async (dispatch) => {
  try {
    const currentUser = await Auth.currentAuthenticatedUser();
    const currentUserGroup = currentUser.signInUserSession.accessToken.payload[
      "cognito:groups"
    ]
      ? currentUser.signInUserSession.accessToken.payload["cognito:groups"][0]
      : "";
    // DEBUGGING;
    //console.log(currentUserGroup);
    dispatch({ type: "CURRENT_USER_GROUP", currentUserGroup });
  } catch (e) {
    onError(e);
  }
};

export const setCurrentUserLocation = async (dispatch) => {
  try {
    // Fetch User Location
    const reponse = await fetch("https://ipapi.co/json/");
    const currentUserLocation = await reponse.json();
    //console.log("currentUserLocation", currentUserLocation);
    dispatch({ type: "CURRENT_USER_LOCATION", currentUserLocation });
  } catch (e) {
    onError(e);
  }
};

export const setUserInfo = async (userinfo, dispatch) => {
  try {
    const currentUser = await Auth.currentAuthenticatedUser();
    if (currentUser) {
      dispatch({ type: "CURRENT_USER_INFO", userinfo });
    }
  } catch (e) {
    onError(e);
  }
};

export const setNonUSResidentToSAccept = async (tosaccept, dispatch) => {
  try {
    const currentUser = await Auth.currentAuthenticatedUser();
    if (currentUser) {
      dispatch({ type: "SET_NONUS_TOS_ACCEPT", tosaccept });
    }
  } catch (e) {
    onError(e);
  }
};

export const setWalletGlobalState = async (walletAddress, dispatch) => {
  try {
    const currentUser = await Auth.currentAuthenticatedUser();
    if (currentUser) {
      dispatch({ type: "SET_WALLET_ADDRESS", walletAddress });
    }
  } catch (e) {
    onError(e);
  }
};

export const setErrors = async (errors, dispatch) => {
  try {
    dispatch({ type: "MUTATE_ERRORS", errors });
  } catch (e) {
    onError(e);
  }
};

/* JOB SEARCH */

// ------------------------------------------------------------------------------
// PORTALS

export const setPortals = async (portals, dispatch) => {
  try {
    dispatch({ type: "MUTATE_PORTALS", portals });
  } catch (e) {
    onError(e);
  }
};

export const setSearchFieldsGlobal = async (searchFieldsGlobal, dispatch) => {
  try {
    dispatch({ type: "MUTATE_SEARCH_FIELDS_GLOBAL", searchFieldsGlobal });
  } catch (e) {
    onError(e);
  }
};
